<template>
  <form class="timecard-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click.self="returnMenu"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      申請&emsp;
      <span v-if="isCreate">新規登録</span><span v-else>確認・修正</span>
    </h1>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <div
      class="row"
      v-if="!disabled_form && request.status == status.send_back"
    >
      <div class="col-md-12">
        <label>承認者コメント</label>
        <div class="comment-area">{{ request.comment }}</div>
      </div>
    </div>
    <div class="row mb-3">
      <label class="mb-0">申請種別</label>
      <div class="btn-group col-md-12">
        <div
          class="btn-group"
          role="group"
          aria-label="kind"
          v-for="(value, key) in kinds"
          :key="key"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.kind }"
            name="kind"
            :id="`kind-${key}`"
            :value="key"
            v-model="form.kind"
            :disabled="
              disabled_form ||
              request.status == status.send_back ||
              $route.query.kind ||
              (employee.need_overtime_calculation &&
                key == kind.direct_return) ||
              (employee.need_overtime_calculation && key == kind.business_trip)
            "
          />
          <label class="btn btn-outline-success py-2" :for="`kind-${key}`">
            {{ value }}
          </label>
        </div>
      </div>
      <div v-if="errors.kind" class="invalid-feedback d-block">
        {{ errors.kind[0] }}
      </div>
    </div>
    <div
      class="row"
      v-if="
        [kind.paid_holiday, kind.special_holiday].includes(Number(form.kind))
      "
    >
      <div class="col-md-6">
        <label>取得(予定)日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.start_date"
          :class="{ 'is-invalid': !!errors.start_date }"
        />
        <div v-if="errors.start_date" class="invalid-feedback d-block">
          {{ errors.start_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>取得(予定)日数</label>
        <input
          type="number"
          class="form-control"
          v-model="form.days"
          :class="{ 'is-invalid': !!errors.days }"
          min="1"
        />
        <div v-if="errors.days" class="invalid-feedback d-block">
          {{ errors.days[0] }}
        </div>
        <p>有休残: {{ employee.day_count_of_paid_holiday }}日</p>
      </div>
      <div class="col-md-6 mb-3" v-if="form.kind == kind.special_holiday">
        <label>特別休暇種別</label><br />
        <div class="btn-group">
          <div
            class="btn-group"
            role="group"
            aria-label="spacial_kind"
            v-for="(value, key) in special"
            :key="key"
          >
            <input
              type="radio"
              class="btn-check"
              :class="{ 'is-invalid': !!errors.special_kind }"
              name="special_kind"
              :id="`special_kind-${key}`"
              :value="key"
              v-model="form.special_kind"
            />
            <label
              class="btn btn-outline-success py-2 px-4"
              :for="`special_kind-${key}`"
            >
              {{ value }}
            </label>
          </div>
        </div>
        <div v-if="errors.special_kind" class="invalid-feedback d-block">
          {{ errors.special_kind[0] }}
        </div>
      </div>
      <div class="col-md-6" v-if="form.kind == kind.special_holiday">
        <label>続柄</label>
        <input
          type="text"
          class="form-control"
          v-model="form.relationship"
          :class="{ 'is-invalid': !!errors.relationship }"
          :disabled="
            !(form.kind == kind.special_holiday && form.special_kind != 3)
          "
        />
        <div v-if="errors.relationship" class="invalid-feedback d-block">
          {{ errors.relationship[0] }}
        </div>
      </div>
      <div class="col-md-12" v-if="form.kind == kind.special_holiday">
        <label>特別休暇種別(その他)</label>
        <input
          type="text"
          class="form-control"
          v-model="form.special_reason"
          :class="{ 'is-invalid': !!errors.special_reason }"
          :disabled="
            !(form.kind == kind.special_holiday && form.special_kind == 3)
          "
        />
        <div v-if="errors.special_reason" class="invalid-feedback d-block">
          {{ errors.special_reason[0] }}
        </div>
      </div>
    </div>
    <div class="row" v-else-if="Number(form.kind) === kind.work_on_holiday">
      <div class="col-md-6">
        <label>出勤日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.start_date"
          :class="{ 'is-invalid': !!errors.start_date }"
        />
        <div v-if="errors.start_date" class="invalid-feedback d-block">
          {{ errors.start_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>指定振替休日日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.related_date"
          :class="{ 'is-invalid': !!errors.related_date }"
        />
        <div v-if="errors.related_date" class="invalid-feedback d-block">
          {{ errors.related_date[0] }}
        </div>
      </div>
      <div
        class="col-sm-6"
        v-if="
          form.start_date &&
          today < form.start_date &&
          !employee.need_overtime_calculation
        "
      >
        <input
          class="form-check-input fs-4"
          type="radio"
          id="work_on_holiday_direct_return1"
          v-model="direct_return"
          :value="3"
        />
        <label for="work_on_holiday_direct_return1" class="my-auto mt-2 me-3">
          &ensp;直行直帰</label
        >
        <input
          class="form-check-input fs-4"
          type="radio"
          id="work_on_holiday_direct_return2"
          v-model="direct_return"
          :value="1"
        />
        <label for="work_on_holiday_direct_return2" class="my-auto mt-2">
          &ensp;社内勤務</label
        >
        <div v-if="errors.action" class="invalid-feedback d-block">
          {{ errors.action[0] }}
        </div>
        <div
          v-if="
            form.start_date &&
            today < form.start_date &&
            !employee.need_overtime_calculation &&
            !direct_return &&
            Number(form.kind) === kind.work_on_holiday
          "
          class="invalid-feedback d-block"
        >
          当日の勤務を選択してください。
        </div>
      </div>
      <div class="col-sm-6" v-else></div>
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <label>実就業時間</label>
        <div class="input-group">
          <input
            type="time"
            class="form-control"
            v-model="form.work_start_at"
            :disabled="
              !(
                form.start_date &&
                (today >= form.start_date || form.action === '3')
              )
            "
          />
          <div class="input-group-text">～</div>
          <input
            type="time"
            class="form-control"
            v-model="form.work_end_at"
            :disabled="
              !(
                form.start_date &&
                (today >= form.start_date || form.action === '3')
              )
            "
          />
        </div>
        <div v-if="errors.work_start_at" class="invalid-feedback d-block">
          {{ errors.work_start_at[0] }}
        </div>
        <div v-if="errors.work_end_at" class="invalid-feedback d-block">
          {{ errors.work_end_at[0] }}
        </div>
      </div>
      <div class="col-sm-6">
        <label>休憩時間</label><br />
        <div class="btn-group">
          <div
            class="btn-group col-sm-4"
            role="group"
            aria-label="break_minutes"
            v-for="break_min in minutes"
            :key="break_min"
          >
            <input
              type="radio"
              class="btn-check"
              :class="{ 'is-invalid': !!errors.break_minutes }"
              name="break"
              :id="`break_minutes-${break_min}`"
              :value="break_min"
              v-model="form.break_minutes"
              :disabled="
                !(
                  form.start_date &&
                  (today >= form.start_date || form.action === '3')
                )
              "
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`break_minutes-${break_min}`"
            >
              {{ break_min }}分
            </label>
          </div>
        </div>
        <div v-if="errors.break_minutes" class="invalid-feedback d-block">
          {{ errors.break_minutes[0] }}
        </div>
      </div>
    </div>
    <div class="row" v-else-if="Number(form.kind) === kind.business_trip">
      <div class="col-md-6">
        <label>出張(予定)日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.start_date"
          :class="{ 'is-invalid': !!errors.start_date }"
        />
        <div v-if="errors.start_date" class="invalid-feedback d-block">
          {{ errors.start_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>出張(予定)日数</label>
        <input
          type="number"
          class="form-control"
          v-model="form.days"
          :class="{ 'is-invalid': !!errors.days }"
          min="1"
        />
        <div v-if="errors.days" class="invalid-feedback d-block">
          {{ errors.days[0] }}
        </div>
      </div>
    </div>
    <div class="row" v-else-if="Number(form.kind) === kind.direct_return">
      <div class="col-md-6">
        <label>直行直帰日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.start_date"
          :class="{ 'is-invalid': !!errors.start_date }"
        />
        <div v-if="errors.start_date" class="invalid-feedback d-block">
          {{ errors.start_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>行動種別</label><br />
        <div class="btn-group">
          <div
            class="btn-group col-sm-6"
            role="group"
            aria-label="kind"
            v-for="(value, key) in actions"
            :key="key"
          >
            <input
              type="radio"
              class="btn-check"
              :class="{ 'is-invalid': !!errors.action }"
              name="action"
              :id="`action-${key}`"
              :value="key"
              v-model="form.action"
            />
            <label class="btn btn-outline-success py-2" :for="`action-${key}`">
              {{ value }}
            </label>
          </div>
        </div>
        <div v-if="errors.action" class="invalid-feedback d-block">
          {{ errors.action[0] }}
        </div>
      </div>
      <div class="col-sm-6">
        <label>実就業時間</label>
        <div class="input-group">
          <input
            type="time"
            class="form-control"
            v-model="form.work_start_at"
            :disabled="form.action == 2 || !form.action"
          />
          <div class="input-group-text">～</div>
          <input
            type="time"
            class="form-control"
            v-model="form.work_end_at"
            :disabled="form.action == 1 || !form.action"
          />
        </div>
        <div v-if="errors.work_start_at" class="invalid-feedback d-block">
          {{ errors.work_start_at[0] }}
        </div>
        <div v-if="errors.work_end_at" class="invalid-feedback d-block">
          {{ errors.work_end_at[0] }}
        </div>
      </div>
      <div class="col-sm-6">
        <label>休憩時間</label><br />
        <div class="btn-group">
          <div
            class="btn-group col-sm-4"
            role="group"
            aria-label="break_minutes"
            v-for="break_min in minutes"
            :key="break_min"
          >
            <input
              type="radio"
              class="btn-check"
              :class="{ 'is-invalid': !!errors.break_minutes }"
              name="break"
              :id="`break_minutes-${break_min}`"
              :value="break_min"
              v-model="form.break_minutes"
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`break_minutes-${break_min}`"
            >
              {{ break_min }}分
            </label>
          </div>
        </div>
        <div v-if="errors.break_minutes" class="invalid-feedback d-block">
          {{ errors.break_minutes[0] }}
        </div>
      </div>
      <div class="col-sm-3">
        <label>外出時間</label>
        <input
          type="time"
          class="form-control"
          v-model="form.go_out_at"
          :disabled="form.action != 2"
        />
        <span class="text-muted">※直帰先へ向かう時間を入力してください。</span>
        <div v-if="errors.go_out_at" class="invalid-feedback d-block">
          {{ errors.go_out_at[0] }}
        </div>
      </div>
      <div class="col-sm-3">
        <label>戻り時間</label>
        <input
          type="time"
          class="form-control"
          v-model="form.return_at"
          :disabled="form.action != 1"
        />
        <span class="text-muted"
          >※直帰先から事務所に戻る時間を入力してください。</span
        >
        <div v-if="errors.return_at" class="invalid-feedback d-block">
          {{ errors.return_at[0] }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>申請理由</label>
        <textarea
          cols="2"
          v-model="form.reason"
          :class="{ 'is-invalid': !!errors.reason }"
          :disabled="disabled_form"
        >
        </textarea>
        <div v-if="errors.reason" class="invalid-feedback d-block">
          {{ errors.reason[0] }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div>
        <button type="button" class="btn btn-secondary" @click="returnMenu">
          キャンセル
        </button>
      </div>
      <button
        type="submit"
        class="btn btn-primary"
        v-if="!disabled_form"
        :disabled="
          form.start_date &&
          today < form.start_date &&
          !employee.need_overtime_calculation &&
          !direct_return &&
          Number(form.kind) === kind.work_on_holiday
        "
      >
        &emsp;登録&emsp;
      </button>
    </div>
    <transition name="fade">
      <div v-if="validated">
        <div class="modal" @click.self="validated = false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title">申請&emsp;確認</h6>
                <button
                  type="button"
                  class="btn-close"
                  @click="validated = false"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="list-group" v-if="requests.length > 0">
                      <li
                        v-for="(value, key) in requests"
                        :key="key"
                        class="list-group-item"
                      >
                        <p>
                          {{ value.start_date }}:{{ value.kind_text }}({{
                            value.status_text
                          }})
                        </p>
                      </li>
                    </ul>
                    <ul class="list-group" v-if="timecards.length > 0">
                      <li
                        v-for="(value, key) in timecards"
                        :key="key"
                        class="list-group-item"
                      >
                        <p>
                          {{ value.date }}:{{ value.kind_text
                          }}<span v-if="value.work_start_time"
                            >({{ value.work_start_time }}～{{
                              value.work_finish_time
                            }})</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-12">
                    {{
                      form.start_date
                    }}に承認待ちの申請または出勤打刻があります。<br />
                    <span v-if="form.kind == kind.direct_return">
                      今までの申請を却下し新しく申請を作成します。<br />申請内容が勤怠に反映されますが、申請を登録してもよろしいですか。
                    </span>
                    <span v-else>申請を登録してもよろしいですか。</span>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    @click.self="validated = !validated"
                  >
                    キャンセル
                  </button>
                  <button type="submit" class="btn btn-primary">
                    &emsp;登録&emsp;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop show"></div>
      </div>
    </transition>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "FormView",
  components: { BsAlert },
  props: {},
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: "",
    today: "",
    request: "",
    requests: [],
    timecards: [],
    kinds: {},
    minutes: [60, 75, 90, 120],
    actions: { 1: "直行", 2: "直帰", 3: "直行/直帰" },
    special: { 1: "忌引き", 2: "結婚休暇", 3: "その他" },
    disabled_form: false,
    validated: false,
    mode: "validation",
    direct_return: null,
    form: {
      kind: "",
      start_date: "",
      days: 1,
      reason: "",
      related_date: "",
      action: null,
      work_start_at: "",
      work_end_at: "",
      go_out_at: "",
      return_at: "",
      break_minutes: "",
      special_kind: "",
      special_reason: "",
      relationship: "",
    },
    kind: {
      work_on_holiday: 2,
      paid_holiday: 4,
      special_holiday: 5,
      direct_return: 8,
      business_trip: 9,
    },
    status: {
      approval_pending: 1,
      approved: 2,
      send_back: 3,
    },
  }),
  mounted() {
    this.$emit("loading", true);
    this.today = this.formatDate(new Date());
    client
      .get("/api/request/request/" + this.$route.params.id)
      .then((res) => {
        this.employee = res.data.employee;
        this.request = res.data.request;
        this.kinds = res.data.kinds;
        this.form = { ...this.form, ...res.data.request };
        this.form.kind = String(this.form.kind);
        this.today = this.formatDate(new Date());
        if (this.$route.query.kind) {
          this.form.kind = this.$route.query.kind;
        }
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
          this.errorMessage = error.response.data.detail;
          this.kinds = error.response.data.kinds;
          this.disabled_form = true;
        }
        this.$emit("loading", false);
      });
  },
  watch: {
    validated() {
      this.mode = this.validated ? "register" : "validation";
    },
    direct_return() {
      this.form.action = this.direct_return === 3 ? "3" : null;
    },
    "form.kind"() {
      this.direct_return = false;
      this.form.action = null;
    },
  },
  computed: {
    isCreate() {
      return this.$route.params.id === "0";
    },
  },
  methods: {
    register() {
      this.$emit("loading", true);
      let requestId = this.$route.params.id;
      let promise =
        requestId != 0
          ? client.put(
              `/api/request/request/${this.$route.params.id}?mode=${this.mode}`,
              this.form
            )
          : client.post(`/api/request/request?mode=${this.mode}`, this.form);

      promise
        .then((res) => {
          this.$emit("loading", false);
          const data = {
            start_date: this.form.start_date,
            employee: this.$route.params.id,
            days: this.form.days,
            kind: this.form.kind,
            related_date: this.form.related_date,
            action: this.form.action,
          };
          if (this.mode === "validation") {
            client
              .get("/api/request/request/check", { params: data })
              .then((res) => {
                this.requests = res.data.requests;
                this.timecards = res.data.timecards;
                if (this.requests.length > 0 || this.timecards.length > 0) {
                  this.validated = true;
                } else {
                  this.mode = "register";
                  this.register();
                }
              });
          } else {
            this.$router.push("/request_list?registered=" + res.data.kind_text);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
    returnMenu() {
      if (this.$route.query.kind) {
        this.$router.push("/");
      } else {
        this.$router.push("/request_list");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
.comment-area,
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
.comment-area {
  white-space: pre-line;
  padding: 3px;
}
.modal {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
