<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">再入社</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <form class="rejoin-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <p>再入社する日付と契約形態を入力してください。</p>
        <div class="mb-3">
          <label>契約形態</label><br />
          <div
            class="form-check form-check-inline"
            v-for="(value, key) in contractTypes"
            :key="key"
          >
            <input
              class="form-check-input"
              type="radio"
              name="type"
              :id="`rejoin-type-${key}`"
              :value="key"
              v-model="form.type"
              :class="{ 'is-invalid': errors.type }"
            />
            <label class="form-check-label" :for="`rejoin-type-${key}`">
              {{ value }}
            </label>
          </div>
          <div v-if="errors.type" class="invalid-feedback d-block">
            {{ errors.type[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>契約開始日</label>
          <input
            type="date"
            class="form-control"
            v-model="form.start_at"
            :class="{ 'is-invalid': !!errors.start_at }"
          />
          <div v-if="errors.start_at" class="invalid-feedback">
            {{ errors.start_at[0] }}
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            キャンセル
          </button>
          <button type="submit" class="btn btn-primary">
            &emsp;登録&emsp;
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "FormView",
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    contract_type: [],
    contractTypes: {
      1: "社員・契約社員",
      2: "パート・アルバイト(時給)",
      3: "パート・アルバイト(日給)",
    },
    errors: {},
    form: {
      start_at: "",
      type: "",
    },
  }),
  watch: {
    id(value) {
      if (value !== -1) {
        this.$emit("loading", true);
        this.errors = {};
        client
          .get(`/api/employee/rejoin/${value}`)
          .then((res) => {
            this.contract_type = res.data;
            this.form.start_at = this.contract_type.end_at;
            this.form.type = this.contract_type.type;
            this.$emit("loading", false);
          })
          .catch((error) => {
            console.error(error);
            this.$emit("loading", false);
          });
      }
    },
  },
  methods: {
    register() {
      this.$emit("loading", true);
      client
        .put(`/api/employee/rejoin/${this.id}`, this.form)
        .then(() => {
          this.$emit("loading", false);
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
