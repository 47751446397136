<template>
  <div
    class="custom-tooltip m-0"
    :id="`tooltip-${index}`"
    :data-bs-html="true"
    :data-bs-original-title="tooltip_messages"
    @mouseover="newTooltip(index)"
    @mouseleave="removeTooltips"
    @click="removeTooltips"
    v-if="tooltip_messages"
  >
    <span class="material-icons">info_outline</span>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap";

export default {
  name: "DetailTooltip",
  props: {
    index: {
      required: false,
      type: Number,
    },
    related_date: {
      required: false,
      type: String,
    },
    request: {
      required: false,
      type: Object,
    },
    kind: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    tooltip_messages: "",
  }),
  mounted() {
    if (this.related_date) {
      const related_date_text = this.kind === 2 ? "振替休日日" : "出勤日";
      this.tooltip_messages += `<span class="text-white">${related_date_text}(${this.related_date})</span>`;
    }

    if (this.request && this.request.action && this.kind !== 7) {
      let created_at =
        this.request.action === 3
          ? this.request.created_at.slice(0, 10)
          : this.request.action === 1
          ? this.request.return_at
          : this.request.go_out_at;
      if (!created_at) {
        created_at = this.request.created_at.slice(11, 20);
      }
      if (this.tooltip_messages !== "") {
        this.tooltip_messages += "<br />";
      }
      this.tooltip_messages += `<span class="text-white">${this.request.action_kind}(${created_at})</span>`;
    }
  },
  methods: {
    newTooltip(index) {
      const myTooltips = new bootstrap.Tooltip(
        document.getElementById(`tooltip-${index}`),
        {
          keyboard: false,
        }
      );
      myTooltips.show();
    },
    removeTooltips() {
      const list = document.querySelectorAll(".tooltip");
      list.forEach(function (box) {
        box.remove();
      });
    },
  },
};
</script>

<style scoped></style>
