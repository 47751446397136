<template>
  <div
    class="custom-tooltip m-0"
    :id="`tooltip-${index}`"
    :data-bs-html="true"
    :data-bs-original-title="tooltip_messages"
    @mouseover="newTooltip(index)"
    @mouseleave="removeTooltips"
    @click="removeTooltips"
    v-if="tooltip_messages"
  >
    <span class="material-icons">info_outline</span>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap";

export default {
  name: "DetailTooltip",
  props: {
    index: {
      required: false,
      type: Number,
    },
    reasons: {
      required: false,
      type: String,
    },
    minutes: {
      required: false,
      type: Number,
    },
    type: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    tooltip_messages: "",
  }),
  mounted() {
    if (this.minutes && this.reasons) {
      if (this.type === 1) {
        this.tooltip_messages += `<span class="text-white">残業リセット理由</span><br />`;
      } else if (this.type === 2) {
        this.tooltip_messages += `<span class="text-white">遅刻早退リセット理由</span><br />`;
      }

      this.tooltip_messages += `<span class="text-white">${this.formatMinutes(
        this.minutes
      )}(${this.reasons})</span>`;
    }
  },
  methods: {
    newTooltip(index) {
      const myTooltips = new bootstrap.Tooltip(
        document.getElementById(`tooltip-${index}`),
        {
          keyboard: false,
        }
      );
      myTooltips.show();
    },
    removeTooltips() {
      const list = document.querySelectorAll(".tooltip");
      list.forEach(function (box) {
        box.remove();
      });
    },
    formatMinutes(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
  },
};
</script>

<style scoped></style>
