<template>
  <form class="timecard-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="
        $router.replace(
          `/timecard?month=${timecard.summary.month}&employee=${timecard.summary.employee}`
        )
      "
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      勤怠管理&emsp;
      <span v-if="isCreate">新規登録</span><span v-else>確認・修正</span>
    </h1>
    <div
      class="row"
      v-if="
        form.kind == kind.work_on_weekdays || form.kind == kind.work_on_holiday
      "
    >
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          総労働時間<br />{{ formatSeconds(form.work_minutes) }}
        </h3>
      </div>
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          勤務時間<br />{{ formatSeconds(form.normal_work_minutes) }}
        </h3>
      </div>
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          休憩時間<br />{{ formatSeconds(form.break_minutes) }}
        </h3>
      </div>
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          遅刻早退時間
          <br />{{ formatSeconds(form.tardy_and_early_leaving_minutes) }}
        </h3>
      </div>
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          残業時間<br />{{ formatSeconds(form.overtime_minutes) }}
        </h3>
      </div>
      <div class="col-sm-2 my-auto">
        <h3 class="mb-0">
          深夜時間<br />{{ formatSeconds(form.midnight_minutes) }}
        </h3>
      </div>
    </div>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <div class="row">
      <div class="col-md-12">
        <label>入力日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.date"
          :class="{ 'is-invalid': !!errors.date }"
          disabled
        />
        <div v-if="errors.date" class="invalid-feedback d-block">
          {{ errors.date[0] }}
        </div>
      </div>
    </div>
    <h2 class="mb-3">勤怠種別</h2>
    <div class="row">
      <div class="btn-group col-md-12">
        <div
          class="btn-group col-md-1"
          role="group"
          aria-label="kind"
          v-for="(value, key) in kinds"
          :key="key"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.kind }"
            name="kind"
            :id="`kind-${key}`"
            :value="key"
            v-model="form.kind"
            :disabled="
              (timecard.kind != kind.work_on_holiday &&
                key == kind.work_on_holiday) ||
              (timecard.kind != kind.substitute_holiday &&
                key == kind.substitute_holiday) ||
              (employee.need_overtime_calculation && key == kind.direct_return)
            "
          />
          <label class="btn btn-outline-success py-2" :for="`kind-${key}`">
            {{ value }}
          </label>
        </div>
      </div>
      <div v-if="errors.kind" class="invalid-feedback d-block">
        {{ errors.kind[0] }}
      </div>
    </div>
    <div
      v-if="
        form.kind != kind.work_on_holiday &&
        form.kind != kind.substitute_holiday &&
        timecard.kind == kind.substitute_holiday
      "
    >
      <h2 class="mb-3">指定振替休日日</h2>
      <div class="row">
        <label class="text-muted m-0">
          代わりの振替休日日を入力してください。
        </label>
        <div class="col-sm-3">
          <input
            type="date"
            class="form-control"
            v-model="form.related_date"
            :class="{ 'is-invalid': !!errors.related_date }"
          />
          <div v-if="errors.related_date" class="invalid-feedback d-block">
            {{ errors.related_date[0] }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        form.kind == kind.work_on_weekdays ||
        form.kind == kind.work_on_holiday ||
        form.kind == kind.direct_return
      "
    >
      <div
        v-if="
          form.kind == kind.work_on_weekdays ||
          form.kind == kind.work_on_holiday
        "
      >
        <h2 class="mb-3">就業予定時間</h2>
        <div class="row row-cols-sm-auto">
          <div
            class="btn-group"
            role="group"
            aria-label="work_shifts"
            @click="useCustom"
          >
            <div
              v-for="shifts in employee.work_shifts"
              :key="shifts.id"
              class="btn-group"
            >
              <input
                class="btn-check"
                type="radio"
                name="work_shifts"
                :id="`shifts-${shifts.id}`"
                :value="{
                  shift_start_at: shifts.start_time,
                  shift_end_at: shifts.end_time,
                }"
                v-model="form.work_shifts"
                :class="{ 'is-invalid': !!errors.work_shifts }"
              />
              <label
                class="btn btn-outline-success py-3"
                :for="`shifts-${shifts.id}`"
              >
                {{ shifts.start_time.slice(0, 5) }}～{{
                  shifts.end_time.slice(0, 5)
                }}
              </label>
            </div>
            <input
              class="btn-check"
              type="radio"
              name="work_shifts"
              id="custom"
              :checked="true"
            />
            <label class="btn btn-outline-success py-3" for="custom">
              カスタム
            </label>
          </div>
        </div>
        <div>
          <div class="mt-3" :hidden="!use_custom">
            <div class="col-sm-4 text-center">始業・終業時間</div>
            <div class="col-sm-4">
              <div class="input-group">
                <input
                  type="time"
                  class="form-control"
                  v-model="form.work_shifts.shift_start_at"
                />
                <div class="input-group-text">～</div>
                <input
                  type="time"
                  class="form-control"
                  v-model="form.work_shifts.shift_end_at"
                />
              </div>
            </div>
          </div>
          <div v-if="errors.shifts_start_time" class="invalid-feedback d-block">
            {{ errors.shifts_start_time[0] }}
          </div>
          <div v-if="errors.shifts_end_time" class="invalid-feedback d-block">
            {{ errors.shifts_end_time[0] }}
          </div>
        </div>
      </div>
      <h2>勤怠時間</h2>
      <div class="row">
        <div class="col-md-4 col-6">
          <div class="input-group">
            <input
              type="time"
              class="form-control"
              v-model="form.work_start_at"
            />
            <div class="input-group-text">～</div>
            <input
              type="time"
              class="form-control"
              v-model="form.work_end_at"
            />
            <div v-if="errors.work_start_at" class="invalid-feedback d-block">
              {{ errors.work_start_at[0] }}
            </div>
            <div v-if="errors.work_end_at" class="invalid-feedback d-block">
              {{ errors.work_end_at[0] }}
            </div>
          </div>
        </div>
      </div>
      <h2 class="mb-3">休憩時間</h2>
      <div class="row">
        <label class="text-muted m-0">本日の休憩時間を選択してください。</label>
        <div class="btn-group col-md-12">
          <div
            class="btn-group col-md-1"
            role="group"
            aria-label="break_minutes"
            v-for="break_min in breakMinutesList"
            :key="break_min"
          >
            <input
              type="radio"
              class="btn-check"
              :class="{ 'is-invalid': !!errors.break_minutes }"
              name="break"
              :id="`break_minutes-${break_min}`"
              :value="break_min"
              v-model="form.break_minutes"
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`break_minutes-${break_min}`"
            >
              {{ break_min }}分
            </label>
          </div>
        </div>
        <div v-if="errors.break_minutes" class="invalid-feedback d-block">
          {{ errors.break_minutes[0] }}
        </div>
      </div>
      <div v-if="form.kind == kind.work_on_holiday">
        <h2 class="mb-3">指定振替休日日</h2>
        <div class="row">
          <div class="col-sm-3">
            <input
              type="date"
              class="form-control"
              v-model="form.related_date"
              :class="{ 'is-invalid': !!errors.related_date }"
              :disabled="timecard.kind === kind.work_on_holiday"
            />
            <div v-if="errors.related_date" class="invalid-feedback d-block">
              {{ errors.related_date[0] }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          form.kind == kind.work_on_weekdays ||
          form.kind == kind.work_on_holiday
        "
      >
        <h2 class="mb-3">勤務場所</h2>
        <div class="row">
          <div class="col-md-12">
            <label class="text-muted"
              >自店勤務以外の場合に入力してください。</label
            >
            <input
              type="text"
              class="form-control"
              v-model="form.support_store"
              :class="{ 'is-invalid': !!errors.support_store }"
            />
            <div v-if="errors.support_store" class="invalid-feedback d-block">
              {{ errors.support_store[0] }}
            </div>
          </div>
        </div>
        <div
          v-if="
            form.tardy_and_early_leaving_minutes > 0 || tardy_and_early_reset
          "
        >
          <h2>遅刻・早退</h2>
          <div class="row">
            <label class="m-0">遅刻早退理由</label>
            <div class="mb-3 btn-group col-md-12">
              <div
                class="btn-group col-md-2"
                role="group"
                aria-label="reasons"
                v-for="reason in reasons"
                :key="reason.value"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="reasons_id"
                  :id="`reasons-${reason.value}`"
                  :value="reason.text"
                  v-model="form.tardy_and_early_leaving_reasons"
                  :class="{
                    'is-invalid': !!errors.tardy_and_early_leaving_reasons,
                  }"
                  :disabled="form.tardy_and_early_leaving_minutes <= 0"
                />
                <label
                  class="btn btn-outline-success py-2"
                  :for="`reasons-${reason.value}`"
                >
                  {{ reason.text }}
                </label>
              </div>
            </div>
            <div
              v-if="errors.tardy_and_early_leaving_reasons"
              class="invalid-feedback d-block"
            >
              {{ errors.tardy_and_early_leaving_reasons[0] }}
            </div>
            <div class="col-md-12 mb-3">
              <label>遅刻・早退理由(その他)</label>
              <textarea
                cols="2"
                v-model="form.other_tardy_and_early_leaving_reasons"
                :class="{
                  'is-invalid': !!errors.other_tardy_and_early_leaving_reasons,
                }"
                :disabled="form.tardy_and_early_leaving_reasons !== 'その他'"
              >
              </textarea>
              <div
                v-if="errors.other_tardy_and_early_leaving_reasons"
                class="invalid-feedback d-block"
              >
                {{ errors.other_tardy_and_early_leaving_reasons[0] }}
              </div>
            </div>
            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-reset p-3"
                :class="{ active: tardy_and_early_reset }"
                @click="tardy_and_early_reset = !tardy_and_early_reset"
              >
                遅刻早退時間リセット
              </button>
            </div>
            <div class="col-md-2">
              <label>遅刻早退リセット時間(分)</label>
              <div class="col-md-12">
                <input
                  type="text"
                  v-model="form.tardy_and_early_leaving_reset_minutes"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      !!errors.tardy_and_early_leaving_reset_minutes,
                  }"
                  :disabled="!tardy_and_early_reset"
                />
              </div>
              <div
                v-if="errors.tardy_and_early_leaving_reset_minutes"
                class="invalid-feedback d-block"
              >
                {{ errors.tardy_and_early_leaving_reset_minutes[0] }}
              </div>
            </div>
            <div class="col-md-8">
              <label>遅刻早退時間リセット理由</label>
              <input
                type="text"
                v-model="form.tardy_and_early_leaving_reset_reasons"
                class="form-control"
                :class="{
                  'is-invalid': !!errors.tardy_and_early_leaving_reset_reasons,
                }"
                :disabled="!tardy_and_early_reset"
              />
              <div
                v-if="errors.tardy_and_early_leaving_reset_reasons"
                class="invalid-feedback d-block"
              >
                {{ errors.tardy_and_early_leaving_reset_reasons[0] }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            employee.need_overtime_calculation &&
            (form.overtime_minutes + form.midnight_minutes > 0) | overtime_reset
          "
        >
          <h2>残業</h2>
          <div class="row">
            <label class="m-0">残業理由</label>
            <div class="mb-3 btn-group col-md-12">
              <div
                class="btn-group col-md-2"
                role="group"
                aria-label="reasons_for_overtime"
                v-for="overtime_reason in reasonsForOvertime"
                :key="overtime_reason.value"
              >
                <input
                  class="btn-check"
                  type="radio"
                  name="overtime_reason_id"
                  :id="`overtime_reason-${overtime_reason.value}`"
                  :value="overtime_reason.text"
                  v-model="form.overtime_reasons"
                  :class="{ 'is-invalid': !!errors.overtime_reasons }"
                  :disabled="
                    !(form.overtime_minutes + form.midnight_minutes > 0)
                  "
                />
                <label
                  class="btn btn-outline-success py-2"
                  :for="`overtime_reason-${overtime_reason.value}`"
                >
                  {{ overtime_reason.text }}
                </label>
              </div>
            </div>
            <div
              v-if="errors.overtime_reasons"
              class="invalid-feedback d-block"
            >
              {{ errors.overtime_reasons[0] }}
            </div>
            <div class="col-md-12">
              <label>残業理由(その他)</label>
              <textarea
                cols="2"
                v-model="form.other_overtime_reasons"
                :class="{ 'is-invalid': !!errors.other_overtime_reasons }"
                :disabled="form.overtime_reasons !== 'その他'"
              >
              </textarea>
              <div
                v-if="errors.other_overtime_reasons"
                class="invalid-feedback d-block"
              >
                {{ errors.other_overtime_reasons[0] }}
              </div>
            </div>
            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-reset p-3 mt-3"
                :class="{ active: overtime_reset }"
                @click="overtime_reset = !overtime_reset"
              >
                残業時間リセット
              </button>
            </div>
            <div class="col-md-10">
              <label>残業時間リセット理由</label>
              <input
                type="text"
                v-model="form.overtime_reset_reasons"
                class="form-control"
                :class="{ 'is-invalid': !!errors.overtime_reset_reasons }"
                :disabled="!overtime_reset"
              />
              <div
                v-if="errors.overtime_reset_reasons"
                class="invalid-feedback d-block"
              >
                {{ errors.overtime_reset_reasons[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="form.kind == kind.special_holiday">
      <div class="row mb-3">
        <div class="col-sm-6">
          <label>特別休暇種別</label><br />
          <div class="btn-group">
            <div
              class="btn-group col-md-8"
              role="group"
              aria-label="spacial_kind"
              v-for="(value, key) in special"
              :key="key"
            >
              <input
                type="radio"
                class="btn-check"
                :class="{ 'is-invalid': !!errors.special_kind }"
                name="special_kind"
                :id="`special_kind-${key}`"
                :value="key"
                v-model="form.special_kind"
              />
              <label
                class="btn btn-outline-success p-2"
                :for="`special_kind-${key}`"
              >
                {{ value }}
              </label>
            </div>
          </div>
          <div v-if="errors.special_kind" class="invalid-feedback d-block">
            {{ errors.special_kind[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label>続柄</label>
          <input
            type="text"
            class="form-control"
            v-model="form.relationship"
            :class="{ 'is-invalid': !!errors.relationship }"
            :disabled="!(form.kind == 5 && form.special_kind != 3)"
          />
          <div v-if="errors.relationship" class="invalid-feedback d-block">
            {{ errors.relationship[0] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>特別休暇種別(その他)</label>
          <textarea
            cols="2"
            v-model="form.special_reason"
            :class="{ 'is-invalid': !!errors.special_reason }"
            :disabled="!(form.kind == 5 && form.special_kind == 3)"
          >
          </textarea>
          <div v-if="errors.special_reason" class="invalid-feedback d-block">
            {{ errors.special_reason[0] }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="form.kind == kind.substitute_holiday" class="row">
      <div class="col-md-12">
        <label>出勤日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.related_date"
          :class="{ 'is-invalid': !!errors.related_date }"
          :disabled="timecard.kind === kind.substitute_holiday"
        />
        <div v-if="errors.related_date" class="invalid-feedback d-block">
          {{ errors.related_date[0] }}
        </div>
      </div>
    </div>
    <div class="row" v-if="Object.keys(kinds).length > 2 || timecard.kind">
      <div class="col-md-12 mb-3">
        <label>勤怠変更理由</label>
        <textarea
          cols="2"
          v-model="form.reasons_for_change"
          :class="{
            'is-invalid': !!errors.timecard_change_history,
          }"
        >
        </textarea>
        <div
          v-if="errors.timecard_change_history"
          class="invalid-feedback d-block"
        >
          {{ errors.timecard_change_history[0] }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>コメント</label>
        <textarea
          cols="2"
          v-model="form.comment"
          :class="{ 'is-invalid': !!errors.comment }"
        >
        </textarea>
        <div v-if="errors.comment" class="invalid-feedback d-block">
          {{ errors.comment[0] }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $router.replace(
              `/timecard?month=${timecard.summary.month}&employee=${timecard.summary.employee}`
            )
          "
        >
          キャンセル
        </button>
      </div>
      <button type="submit" class="btn btn-primary">&emsp;登録&emsp;</button>
    </div>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "FormView",
  components: { BsAlert },
  data: () => ({
    breakMinutesList: [0, 30, 60, 75, 90, 120, 150, 180, 210, 240],
    kinds: [],
    reasons: [],
    reasonsForOvertime: [],
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {},
    work_start_minutes: "",
    work_finish_minutes: "",
    use_custom: true,
    today: "",
    standard_start_at: 0,
    standard_end_at: 0,
    special: { 1: "忌引き", 2: "結婚休暇", 3: "その他" },
    tardy_and_early_reset: false,
    overtime_reset: false,
    didMounted: false,
    form: {
      kind: "",
      work_start_at: "",
      work_end_at: "",
      break_minutes: "",
      support_store: "",
      total_minutes: "",
      work_minutes: "",
      normal_work_minutes: "",
      overtime_minutes: "",
      midnight_minutes: "",
      tardy_and_early_leaving_minutes: "",
      tardy_and_early_leaving_reasons: "",
      other_tardy_and_early_leaving_reasons: "",
      overtime_reasons: "",
      other_overtime_reasons: "",
      tardy_and_early_leaving_reset_minutes: 0,
      overtime_reset_minutes: 0,
      tardy_and_early_leaving_reset_reasons: "",
      overtime_reset_reasons: "",
      date: "",
      comment: "",
      shifts_start_time: "",
      shifts_end_time: "",
      related_date: "",
      reasons_for_change: "",
      special_kind: "",
      special_reason: "",
      relationship: "",
      work_shifts: {
        shift_start_at: "",
        shift_end_at: "",
      },
    },
    kind: {
      work_on_weekdays: 1,
      work_on_holiday: 2,
      public_holiday: 3,
      paid_holiday: 4,
      special_holiday: 5,
      absence: 6,
      substitute_holiday: 7,
      direct_return: 8,
      business_trip: 9,
    },
    round_unit: 30,
  }),
  computed: {
    isCreate() {
      return this.$route.params.identification === "create";
    },
    totalMinutes() {
      if (this.form.work_start_at === this.form.work_end_at) {
        return 0;
      }
      const round_unit = this.round_unit;
      let start_at = null;
      let end_at = null;
      let shift_start_at = null;
      let standard_start_at = 0;

      if (this.form.work_shifts.shift_start_at) {
        shift_start_at =
          parseInt(this.form.work_shifts.shift_start_at.match(/\d+/g)[0]) * 60 +
          parseInt(this.form.work_shifts.shift_start_at.match(/\d+/g)[1]);
        standard_start_at =
          shift_start_at % round_unit === 0
            ? 0
            : 30 - (shift_start_at % round_unit);
      }
      if (this.form.work_start_at) {
        start_at =
          parseInt(this.form.work_start_at.match(/\d+/g)[0]) * 60 +
          parseInt(this.form.work_start_at.match(/\d+/g)[1]);
        start_at =
          this.roundUpMinutes(start_at + standard_start_at) - standard_start_at;
        if (shift_start_at < start_at) {
          standard_start_at =
            start_at % round_unit === 0 ? 0 : 30 - (start_at % round_unit);
        }

        if (start_at < 0) {
          start_at = 0;
        }
      }
      if (this.form.work_end_at) {
        end_at =
          parseInt(this.form.work_end_at.match(/\d+/g)[0]) * 60 +
          parseInt(this.form.work_end_at.match(/\d+/g)[1]);
        end_at =
          this.roundDownMinutes(end_at + standard_start_at) - standard_start_at;
        if (end_at < 0) {
          end_at = 0;
        }
      }

      if ((!!start_at && !!end_at) || (!!start_at && end_at == 0)) {
        end_at = end_at <= 300 && start_at > end_at ? end_at + 24 * 60 : end_at;
        if (
          this.form.kind != this.kind.direct_return &&
          shift_start_at &&
          start_at > shift_start_at
        ) {
          this.calcOvertime(start_at, end_at, standard_start_at);
          return end_at - start_at;
        } else if (
          this.form.kind != this.kind.direct_return &&
          shift_start_at
        ) {
          this.calcOvertime(shift_start_at, end_at, standard_start_at);
          return end_at - shift_start_at;
        } else {
          this.calcOvertime(shift_start_at, end_at, standard_start_at);
          return end_at - start_at;
        }
      } else {
        this.calcOvertime(0, 0, standard_start_at);
        return 0;
      }
    },
  },
  watch: {
    overtime_reset(newValue) {
      this.calcWorkMinutes();
      if (newValue) {
        this.form.overtime_reasons = "";
      } else {
        this.form.overtime_reset_reasons = "";
      }
    },
    tardy_and_early_reset(newValue) {
      this.calcTardyAndEarly();
      if (newValue) {
        this.form.tardy_and_early_leaving_reasons = "";
      } else {
        this.form.tardy_and_early_leaving_reset_reasons = "";
      }
    },
    "form.tardy_and_early_leaving_reset_minutes"() {
      this.calcTardyAndEarly();
    },
    "form.break_minutes"() {
      this.calcWorkMinutes();
      this.calcTardyAndEarly();
    },
    "form.work_start_at"() {
      this.calcWorkMinutes();
      this.calcTardyAndEarly();
    },
    "form.work_end_at"() {
      this.calcWorkMinutes();
      this.calcTardyAndEarly();
    },
    "form.work_shifts.shift_start_at"() {
      this.calcWorkMinutes();
      this.calcTardyAndEarly();
    },
    "form.work_shifts.shift_end_at"() {
      this.calcWorkMinutes();
      this.calcTardyAndEarly();
    },
    "form.kind"() {
      if (this.form.kind == this.kind.direct_return) {
        this.form.work_shifts.shift_start_at = "";
        this.form.work_shifts.shift_end_at = "";
        this.calcWorkMinutes();
        this.calcTardyAndEarly();
      }
      if (this.timecard.kind == this.kind.substitute_holiday) {
        this.form.related_date =
          this.form.kind == this.kind.substitute_holiday
            ? this.timecard.related_date
            : "";
      }
    },
    "form.tardy_and_early_leaving_reasons"() {
      if (this.form.tardy_and_early_leaving_reasons !== "その他") {
        this.form.other_tardy_and_early_leaving_reasons = "";
      }
    },
    "form.overtime_reasons"() {
      if (this.form.overtime_reasons !== "その他") {
        this.form.other_overtime_reasons = "";
      }
    },
  },
  mounted() {
    this.$emit("loading", true);
    this.today = this.formatDate(new Date());
    client
      .get("/api/timecard/attendance/" + this.$route.params.id)
      .then((res) => {
        this.employee = res.data.employee;

        if (this.employee.contract_type == 1 && this.employee.occupation == 1) {
          this.round_unit = 15;
        }
        console.log(this.round_unit);
        this.timecard = res.data.timecard;
        this.reasons = res.data.reasons;
        this.reasonsForOvertime = res.data.reasons_for_overtime;
        this.form.date = this.timecard.date;
        this.kinds = res.data.kinds;
        this.form.kind = this.timecard.kind ? String(this.timecard.kind) : "";
        this.form.comment = this.timecard.comment ? this.timecard.comment : "";
        this.form.related_date = this.timecard.related_date;
        if (
          [
            this.kind.work_on_weekdays,
            this.kind.work_on_holiday,
            this.kind.direct_return,
          ].includes(this.timecard.kind)
        ) {
          this.form.work_start_at = this.timecard.work_start_time;
          this.form.work_end_at = this.timecard.work_finish_time;
          this.form.break_minutes = this.timecard.break_minutes
            ? this.timecard.break_minutes
            : 60;
          this.form.tardy_and_early_leaving_reasons =
            this.timecard.tardy_and_early_leaving_reasons;
          this.form.other_tardy_and_early_leaving_reasons =
            this.timecard.other_tardy_and_early_leaving_reasons;
          this.form.overtime_reasons = this.timecard.overtime_reasons;
          this.form.other_overtime_reasons =
            this.timecard.other_overtime_reasons;
          this.form.tardy_and_early_leaving_reset_reasons =
            this.timecard.tardy_and_early_leaving_reset_reasons;
          this.form.overtime_reset_reasons =
            this.timecard.overtime_reset_reasons;
          if (
            this.timecard.tardy_and_early_leaving_reset_minutes &&
            this.timecard.tardy_and_early_leaving_reset_minutes > 0
          ) {
            this.form.tardy_and_early_leaving_reset_minutes =
              this.timecard.tardy_and_early_leaving_reset_minutes;
            this.tardy_and_early_reset = true;
          }
          if (
            this.timecard.overtime_reset_minutes &&
            this.timecard.overtime_reset_minutes > 0
          ) {
            this.form.overtime_reset_minutes =
              this.timecard.overtime_reset_minutes;
            this.overtime_reset = true;
          }
          this.form.comment = this.timecard.comment;
          this.form.support_store = this.timecard.support_store;
          this.form.work_shifts.shift_start_at =
            this.timecard.shifts_start_time;
          this.form.work_shifts.shift_end_at = this.timecard.shifts_end_time;
        }
        this.form.special_reason = this.timecard.special_holiday || "";
        this.form.special_kind = this.timecard.special_kind || "";
        this.form.relationship = this.timecard.relationship || "";
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
          this.$router.replace(
            `/timecard?errors=${error.response.data.detail}`
          );
        }
        this.$emit("loading", false);
      });
  },
  methods: {
    register() {
      this.$emit("loading", true);
      this.errors = {};
      this.form.shifts_start_time = this.form.work_shifts.shift_start_at;
      this.form.shifts_end_time = this.form.work_shifts.shift_end_at;
      client
        .put(`/api/timecard/attendance/${this.$route.params.id}`, this.form)
        .then((res) => {
          this.$emit("loading", false);
          this.$router.replace(
            "/timecard?registered=" +
              res.data.date +
              "&employee=" +
              res.data.summary.employee +
              "&month=" +
              res.data.summary.month
          );
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            this.errorMessage = error.response.data.work_minutes;
          }
          this.$emit("loading", false);
        });
    },
    formatSeconds(minutes) {
      if (!minutes) {
        return "00:00";
      }
      let hour =
        minutes > 0
          ? Math.floor(minutes / 60)
          : "-" + Math.floor((minutes * -1) / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    roundUpMinutes(minutes) {
      return Math.ceil(minutes / this.round_unit) * this.round_unit;
    },
    roundDownMinutes(minutes) {
      return Math.floor(minutes / this.round_unit) * this.round_unit;
    },
    useCustom() {
      let checked = document.getElementById("custom");
      this.use_custom = checked === document.activeElement;
    },
    calcOvertime(start_at, end_at, round_unit) {
      let unit = round_unit ? 30 - round_unit : 0;
      if (
        !this.employee.need_overtime_calculation ||
        this.form.kind == this.kind.direct_return
      ) {
        this.form.overtime_minutes = 0;
        this.form.midnight_minutes = 0;
      } else {
        let break_minutes = this.form.break_minutes || 0;
        let work_minutes = end_at - start_at - break_minutes;
        let overtime_start = start_at + 480 + break_minutes;
        if (work_minutes <= 480) {
          this.form.midnight_minutes = 0;
          this.form.overtime_minutes = 0;
        } else if (end_at <= 1320 + unit) {
          this.form.midnight_minutes = 0;
          this.form.overtime_minutes = this.roundDownMinutes(
            work_minutes - 480
          );
        } else if (overtime_start >= 1320 + unit) {
          this.form.midnight_minutes = this.roundDownMinutes(
            end_at - 1320 - unit
          );
          this.form.overtime_minutes = 0;
        } else {
          this.form.midnight_minutes = this.roundDownMinutes(
            end_at - 1320 - unit
          );
          this.form.overtime_minutes = this.roundDownMinutes(
            1320 + unit - overtime_start
          );
        }
      }
      if (this.overtime_reset) {
        this.form.overtime_reset_minutes =
          this.form.overtime_minutes + this.form.midnight_minutes;
        this.form.overtime_minutes = 0;
        this.form.midnight_minutes = 0;
      } else {
        this.form.overtime_reset_minutes = 0;
      }
    },
    calcTardyAndEarly() {
      const round_unit = this.round_unit;
      if (this.employee.contract_type == 2) {
        this.form.tardy_and_early_leaving_minutes = 0;
      } else if (this.form.kind != 1 && this.form.kind != 2) {
        this.form.tardy_and_early_leaving_minutes = 0;
      } else {
        this.form.tardy_and_early_leaving_minutes = 0;
        let start_at = null;
        let end_at = null;
        let shift_start_at = null;
        let shift_end_at = null;

        if (this.form.work_shifts.shift_start_at) {
          shift_start_at =
            parseInt(this.form.work_shifts.shift_start_at.match(/\d+/g)[0]) *
              60 +
            parseInt(this.form.work_shifts.shift_start_at.match(/\d+/g)[1]);
          this.standard_start_at =
            shift_start_at % round_unit === 0
              ? 0
              : 30 - (shift_start_at % round_unit);
        } else {
          this.standard_start_at = 0;
        }
        if (this.form.work_shifts.shift_end_at) {
          shift_end_at =
            parseInt(this.form.work_shifts.shift_end_at.match(/\d+/g)[0]) * 60 +
            parseInt(this.form.work_shifts.shift_end_at.match(/\d+/g)[1]);
          this.standard_end_at =
            shift_end_at % round_unit === 0
              ? 0
              : 30 - (shift_end_at % round_unit);
        } else {
          this.standard_end_at = 0;
        }

        if (this.form.work_start_at) {
          start_at =
            parseInt(this.form.work_start_at.match(/\d+/g)[0]) * 60 +
            parseInt(this.form.work_start_at.match(/\d+/g)[1]);
          start_at =
            this.roundUpMinutes(start_at + this.standard_start_at) -
            this.standard_start_at;
          if (start_at < 0) {
            start_at = 0;
          }
        }
        if (this.form.work_end_at) {
          end_at =
            parseInt(this.form.work_end_at.match(/\d+/g)[0]) * 60 +
            parseInt(this.form.work_end_at.match(/\d+/g)[1]);
          end_at =
            this.roundDownMinutes(end_at + this.standard_end_at) -
            this.standard_end_at;
          if (end_at < 0) {
            end_at = 0;
          }
        }

        if ((!!start_at && !!end_at) || (!!start_at && end_at == 0)) {
          end_at =
            end_at <= 300 && start_at > end_at ? end_at + 24 * 60 : end_at;
        }
        if (!!start_at && !!shift_start_at && start_at > shift_start_at) {
          this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
            start_at - shift_start_at
          );
        }

        if (!!start_at && !!end_at && !!shift_start_at && !!shift_end_at) {
          let break_minutes = this.form.break_minutes || 0;
          let shift = shift_end_at - shift_start_at - 60;
          let work_start_at =
            shift_start_at > start_at ? shift_start_at : start_at;
          let work_minutes = this.roundDownMinutes(end_at - work_start_at);
          work_minutes = this.roundDownMinutes(work_minutes - break_minutes);
          if (
            shift >= 480 &&
            work_minutes < 480 &&
            this.form.tardy_and_early_leaving_minutes === 0
          ) {
            this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
              480 - work_minutes
            );
          } else if (
            shift >= 480 &&
            work_minutes + this.form.tardy_and_early_leaving_minutes < 480
          ) {
            this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
              480 - work_minutes - this.form.tardy_and_early_leaving_minutes
            );
          } else if (shift < 480 && end_at < shift_end_at) {
            this.form.tardy_and_early_leaving_minutes += this.roundUpMinutes(
              shift_end_at - end_at
            );
          }
        }
        if (this.tardy_and_early_reset) {
          this.form.tardy_and_early_leaving_minutes =
            this.form.tardy_and_early_leaving_minutes -
            this.form.tardy_and_early_leaving_reset_minutes;
          if (this.form.tardy_and_early_leaving_minutes <= 0) {
            this.form.tardy_and_early_leaving_reasons = "";
          }
        } else {
          this.form.tardy_and_early_leaving_reset_minutes = 0;
        }
      }
    },
    calcWorkMinutes() {
      this.form.total_minutes =
        this.totalMinutes - this.form.overtime_reset_minutes;
      const work_minutes = this.totalMinutes - this.form.break_minutes;
      this.form.work_minutes =
        this.roundDownMinutes(work_minutes) - this.form.overtime_reset_minutes;
      this.form.normal_work_minutes =
        this.roundDownMinutes(work_minutes) -
        this.form.overtime_minutes -
        this.form.overtime_reset_minutes -
        this.form.midnight_minutes;
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
.btn {
  &.btn-reset {
    line-height: 1;
    color: white;
    background-color: #e3598d;

    &:disabled {
      background-color: #aaa;
    }
    &.active {
      background-color: #ff93bc;
    }
  }

  span {
    color: white;
  }
}
</style>
