<template>
  <button
    class="btn btn-secondary mb-3 d-flex align-items-center"
    @click="$router.replace(`/timecard?employee=${employee.identification}`)"
  >
    <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
  </button>
  <h1 class="mb-3">勤怠管理&emsp;詳細</h1>
  <div class="content">
    <table id="summary" class="w-100 mb-3">
      <tr
        v-if="
          timecard &&
          timecard.employee &&
          timecard.employee[0] !== user.identification
        "
      >
        <th>対象者</th>
        <td colspan="3" class="text-left">
          {{ timecard.employee[0] }}：{{ timecard.employee[1] }}
        </td>
      </tr>
      <tr>
        <th>就業日</th>
        <td>{{ timecard.date || "" }}</td>
        <th>種別</th>
        <td>{{ timecard.kind_text || "" }}</td>
      </tr>
      <tr v-if="timecard.work_start_at">
        <th colspan="2">開始～終了(実就業時間)</th>
        <td colspan="2">
          {{ timecard.work_start_time }}～{{ timecard.work_finish_time }}({{
            formatSeconds(timecard.work_minutes)
          }})
        </td>
      </tr>
      <tr v-if="timecard.shifts_start_time && timecard.shifts_end_time">
        <th colspan="2">開始～終了(就業予定時間)</th>
        <td colspan="2">
          {{
            timecard.shifts_start_time
              ? timecard.shifts_start_time.slice(0, 5)
              : ""
          }}～{{
            timecard.shifts_end_time ? timecard.shifts_end_time.slice(0, 5) : ""
          }}
        </td>
      </tr>
      <tr v-if="timecard.work_start_at && timecard.work_end_at">
        <th colspan="2">休憩時間</th>
        <td colspan="2">{{ timecard.break_minutes || 0 }}分</td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.overtime_minutes &&
          timecard.overtime_minutes > 0
        "
      >
        <th>普通残業時間</th>
        <td>{{ formatSeconds(timecard.overtime_minutes) }}</td>
        <th>深夜残業時間</th>
        <td>{{ formatSeconds(timecard.midnight_minutes) }}</td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.overtime_minutes > 0
        "
      >
        <th>残業理由</th>
        <td colspan="3">{{ timecard.overtime_reasons || "" }}</td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.overtime_reset_minutes &&
          timecard.overtime_reset_minutes > 0
        "
      >
        <th colspan="2">残業リセット時間</th>
        <td colspan="2">
          {{ formatSeconds(timecard.overtime_reset_minutes) }}
        </td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.overtime_reset_minutes &&
          timecard.overtime_reset_minutes > 0
        "
      >
        <th colspan="2">残業時間リセット理由</th>
        <td colspan="2">{{ timecard.overtime_reset_reasons || "" }}</td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.tardy_and_early_leaving_minutes &&
          timecard.tardy_and_early_leaving_minutes > 0
        "
      >
        <th colspan="2">遅刻早退時間</th>
        <td colspan="2">
          {{ formatSeconds(timecard.tardy_and_early_leaving_minutes) }}
        </td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.tardy_and_early_leaving_minutes &&
          timecard.tardy_and_early_leaving_minutes > 0
        "
      >
        <th>遅刻早退理由</th>
        <td colspan="3">
          {{ timecard.tardy_and_early_leaving_reasons || "" }}
        </td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.tardy_and_early_leaving_reset_minutes &&
          timecard.tardy_and_early_leaving_reset_minutes > 0
        "
      >
        <th colspan="2">遅刻早退リセット時間</th>
        <td colspan="2">
          {{ formatSeconds(timecard.tardy_and_early_leaving_reset_minutes) }}
        </td>
      </tr>
      <tr
        v-if="
          timecard.work_start_at &&
          timecard.work_end_at &&
          timecard.tardy_and_early_leaving_reset_minutes &&
          timecard.tardy_and_early_leaving_reset_minutes > 0
        "
      >
        <th colspan="2">遅刻早退時間リセット理由</th>
        <td colspan="2">
          {{ timecard.tardy_and_early_leaving_reset_reasons || "" }}
        </td>
      </tr>
      <tr v-if="timecard.kind == kind.special_holiday">
        <th>特別休暇種別</th>
        <td colspan="3">
          {{ timecard.special_kind_text }}
          <span style="white-space: pre-line" v-if="timecard.special_kind == 3"
            >({{ timecard.special_reason }})
          </span>
          <span v-else-if="timecard.special_kind == 1"
            >({{ timecard.relationship }})
          </span>
        </td>
      </tr>
      <tr v-if="timecard.kind == kind.work_on_holiday">
        <th>振替休日日</th>
        <td colspan="3">{{ timecard.related_date }}</td>
      </tr>
      <tr v-if="timecard.kind == kind.substitute_holiday">
        <th>出勤日</th>
        <td colspan="3">{{ timecard.related_date }}</td>
      </tr>
      <tr v-if="timecard.request">
        <th>申請日時</th>
        <td colspan="3">
          {{ timecard.request ? timecard.request.created_at : "" }}
        </td>
      </tr>
      <tr v-if="timecard.request">
        <th>申請理由</th>
        <td colspan="3">
          {{ timecard.request ? timecard.request.reason : "" }}
        </td>
      </tr>
      <tr>
        <th class="no-print">コメント</th>
        <td colspan="3">
          <span style="white-space: pre-line">{{ timecard.comment }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import client from "@/plugins/axios";

export default {
  name: "DetailView",
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {
      summary: {
        month: "",
      },
      request: {
        created_at: "",
        reason: "",
      },
    },
    kind: {
      not_enrolled: 0,
      work_on_weekdays: 1,
      work_on_holiday: 2,
      public_holiday: 3,
      paid_holiday: 4,
      special_holiday: 5,
      absence: 6,
      substitute_holiday: 7,
      direct_return: 8,
      business_trip: 9,
    },
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    this.$emit("loading", true);

    client
      .get("/api/timecard/timecard_detail/" + this.$route.params.id)
      .then((res) => {
        this.timecard = res.data.timecard;
        this.employee = res.data.employee;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        this.$emit("loading", false);
        this.$router.replace(`/timecard?errors=${error.response.data.detail}`);
      });
  },
  methods: {
    formatSeconds(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
.content {
  width: 650px;
  margin: auto;
}
#summary {
  table-layout: fixed;
}
#summary th {
  text-align: center;
  border: solid 1px #333;
  background-color: #ccc;
}
#summary td {
  padding: 0.5rem;
  border: solid 1px #333;
}
</style>
