const state = {
  hostname: "",
};

const mutations = {
  setHostname(state, value) {
    state.hostname = value;
  },
};

const getters = {
  hostname: (state) => {
    return state.hostname;
  },
};

const actions = {
  setHostname({ commit }, value) {
    commit("setHostname", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
