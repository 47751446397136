<template>
  <div>
    <h1>ログイン</h1>
    <form class="w-50 mr-auto" @submit.prevent="login">
      <div>
        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />
      </div>
      <div class="mb-3">
        <label for="identification" class="form-label">社員番号</label>
        <input
          type="text"
          id="identification"
          class="form-control"
          :class="{ 'is-invalid': !!errors.identification }"
          autofocus
          v-model="form.identification"
        />
        <div v-if="errors.identification" class="invalid-feedback">
          {{ errors.identification[0] }}
        </div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">パスワード</label>
        <input
          type="password"
          id="password"
          class="form-control"
          :class="{ 'is-invalid': !!errors.password }"
          v-model="form.password"
        />
        <div v-if="errors.password" class="invalid-feedback">
          {{ errors.password[0] }}
        </div>
      </div>

      <div>
        <button class="btn btn-primary">ログイン</button>
      </div>
    </form>
  </div>
</template>

<script>
import * as constants from "@/constants";
import client from "@/plugins/axios";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "LoginView",
  components: { BsAlert },
  data() {
    return {
      form: {
        identification: "",
        password: "",
      },
      errors: {},
      errorMessage: "",
      downloadUrl: "",
    };
  },
  mounted() {
    this.$emit("loading", false); // 他画面で表示したローディングを非表示にする
  },
  methods: {
    login() {
      this.$emit("loading", true);
      client
        .post("/api/login", this.form)
        .then((res) => {
          this.loggedIn(res.data);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.$emit("loading", false);
            this.errors = { ...error.response.data };
            this.errorMessage = this.errors.non_field_errors[0];
          }
        });
    },
    loggedIn(responseData) {
      this.$store.dispatch("user/loggedIn", responseData);
      this.$router.push(this.$route.query.redirect || "/");
      this.$emit("loading", false);
    },
  },
};
</script>

<style scoped></style>
