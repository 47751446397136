<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">マスター管理&emsp;登録・編集</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <form class="choice-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <div class="mb-3">
          <label>項目名</label>
          <input
            type="text"
            class="form-control"
            v-model="form.text"
            :class="{ 'is-invalid': !!errors.text }"
            id="text"
          />
          <div v-if="errors.text" class="invalid-feedback">
            {{ errors.text[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>選択値</label>
          <input
            type="text"
            class="form-control"
            v-model="form.value"
            :class="{ 'is-invalid': !!errors.value }"
          />
          <div v-if="errors.value" class="invalid-feedback">
            {{ errors.value[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>種別</label>
          <select
            class="form-select"
            v-model="form.kind"
            :class="{ 'is-invalid': !!errors.kind }"
          >
            <option v-for="(value, key) in kinds" :key="key" :value="key">
              {{ value }}
            </option>
          </select>
          <div v-if="errors.kind" class="invalid-feedback">
            {{ errors.kind[0] }}
          </div>
        </div>
        <div class="mb-3">
          <div class="mb-1">画面表示</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              name="target"
              id="is_valid"
              value="1"
              v-model="form.is_valid"
              :class="{ 'is-invalid': !!errors.is_valid }"
            />
            <label class="form-check-label" for="is_valid">表示する</label>
          </div>
          <div v-if="errors.is_valid" class="invalid-feedback d-block">
            {{ errors.is_valid[0] }}
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            v-if="initial_value != 9999"
          >
            &emsp;登録&emsp;
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "ChoiceFormView",
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    kinds: {},
    errors: {},
    initial_value: "",
    form: {
      kind: "",
      value: "",
      text: "",
      is_valid: "",
    },
  }),
  watch: {
    id(value) {
      this.$emit("loading", true);
      this.errors = {};
      client
        .get(`/api/system/choice/${value}`)
        .then((res) => {
          this.initial_value = res.data.choices.value;
          this.form = { ...res.data.choices };
          this.kinds = { ...res.data.kinds };
          Object.keys(this.form).forEach((key) => {
            this.form[key] = this.form[key] ?? "";
          });
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
  },
  methods: {
    register() {
      this.$emit("loading", true);
      let promise =
        this.id > 0
          ? client.put(`/api/system/choice/${this.id}`, this.form)
          : client.post("/api/system/choice", this.form);

      promise
        .then(() => {
          this.$emit("loading", false);
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
