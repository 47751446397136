<template>
  <form class="export-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">給与連携&emsp;</h1>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <h2>出力年月</h2>
    <div class="col-sm-4 m-3">
      <input type="month" class="form-control" v-model="form.month" />
    </div>
    <div v-if="target_month" class="mx-3">
      <span class="text-muted">{{ target_month }}</span>
    </div>
    <h2>出力指定</h2>
    <div class="btn-group col-md-12 m-3">
      <div
        class="btn-group"
        role="group"
        aria-label="break_minutes"
        v-for="(value, key) in contracts"
        :key="key"
      >
        <input
          type="radio"
          class="btn-check"
          name="break"
          :id="`break_minutes-${key}`"
          v-model="form.contract"
          :value="key"
        />
        <label
          class="btn btn-outline-success py-3 px-5"
          :for="`break_minutes-${key}`"
        >
          {{ value }}
        </label>
      </div>
    </div>
    <div class="col-sm-6 m-3">
      <button
        class="btn btn-export"
        @click="exportSalary"
        :disabled="!(form.contract && form.month)"
      >
        勤怠データ出力
      </button>
    </div>
  </form>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "ExportView",
  components: { BsAlert },
  data: () => ({
    kinds: [],
    reasons: [],
    reasonsForOvertime: [],
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {},
    now: "",
    contracts: { 1: "社員・契約社員", 2: "パート・アルバイト" },
    work_start_minutes: "",
    work_finish_minutes: "",
    target_month: "",
    form: {
      month: "",
      contract: "",
    },
  }),
  watch: {
    "form.month"(month) {
      if (month) {
        this.target_month = `社員(${this.closing_date(
          month,
          1
        )}分)、アルバイト(${this.closing_date(
          month,
          2
        )}分)の勤怠が出力されます。`;
      } else {
        this.target_month = "";
      }
    },
  },
  methods: {
    closing_date(month, contract) {
      const y = Number(month.slice(0, 4));
      const m = Number(month.slice(-2));
      console.log(month, y, m, y + m);
      if (contract === 1) {
        return `${m === 1 ? y - 1 : y}年${
          m === 1 ? 12 : m - 1
        }月21日～${y}年${m}月20日`;
      } else if (contract === 2) {
        return `${y}年${m}月6日～${m === 12 ? y + 1 : y}年${
          m === 12 ? 1 : m + 1
        }月5日`;
      }
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + m + d;
    },
    exportSalary: function () {
      this.$emit("loading", true);
      client({
        url: "/api/system/salary/export",
        method: "GET",
        responseType: "blob",
        params: this.form,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const today = this.formatDate(new Date());
          link.setAttribute("download", `kintai_data_${today}.csv`);
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn {
  &.btn-export {
    line-height: 1;
    width: 250px;
    height: 80px;
    font-size: 22px;
    color: white;

    &:disabled {
      background-color: #aaa;
    }
  }

  &.btn-export {
    background-color: #56b9cc;
  }

  span {
    color: white;
  }
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
</style>
