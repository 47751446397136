<template>
  <div class="no-print mx-5">
    <button @click="printTimecard" class="btn btn-secondary mb-2">
      PDFで保存/印刷
    </button>
    <p class="text-muted">
      勤怠確定後に自動で作成します。確定前や、編集後の出勤簿をダウンロードする場合は集計画面から作成予約をしてください。
    </p>
    <hr />
  </div>
  <div v-html="timecards" @dblclick="printTimecard"></div>
</template>

<script>
import client from "@/plugins/axios";

export default {
  name: "TimecardDownload",
  data: () => ({
    timecards: "",
  }),
  mounted() {
    this.$emit("loading", true);
    client
      .get("/api/system/attendance_record", {
        params: {
          month: this.$route.query.month,
          type: this.$route.query.type,
        },
      })
      .then((res) => {
        this.$emit("loading", false);
        this.timecards = res.data;
      })
      .catch(() => {
        this.$emit("loading", false);
      });
  },
  methods: {
    printTimecard() {
      window.print();
    },
  },
};
</script>

<style scoped>
@media print {
  div.no-print {
    display: none;
  }
}
@page {
  size: A4;
  margin: auto 0;
  padding: 0;
}
</style>
