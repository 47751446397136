<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">退社日の取り消し</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        color="danger"
        message="最終支給月翌月以降で、給与連携されない月がないことを確認してから取り消してください。"
      />
      <div class="d-flex justify-content-center my-3">
        <table style="width: 80%">
          <tbody>
            <tr>
              <th>退社日</th>
              <td class="text-end">{{ employee.retirement_at }}</td>
            </tr>
            <tr>
              <th>最終支給月</th>
              <td class="text-end">
                <span v-if="employee.last_payment_month"
                  >{{ employee.last_payment_month.slice(0, 4) }}年{{
                    employee.last_payment_month.slice(4, 6)
                  }}月</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <bs-alert
        v-if="this.errors && this.errors['messages']"
        color="danger"
        :message="this.errors['messages']"
      />

      <div class="d-flex justify-content-around mt-3">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          キャンセル
        </button>
        <button type="button" class="btn btn-danger" @click="register">
          &emsp;退社日を取り消す&emsp;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert";

export default {
  name: "CancelRetireFornView",
  components: { BsAlert },
  props: {
    employee: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    errors: {},
  }),
  methods: {
    register() {
      this.$emit("loading", true);
      let data = { ...this.employee };
      client
        .put(
          `/api/employee/cancel/retirement/${this.employee.identification}`,
          data
        )
        .then(() => {
          this.$emit("loading", false);
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
table {
}
table tr th,
table tr td {
  border: solid 1px #333;
  padding: 5px 10px;
}
</style>
