<template>
  <div class="employee-list">
    <div class="no-print">
      <button
        class="btn btn-secondary mb-3 d-flex align-items-center"
        @click="$router.push('/')"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <h1 class="mb-3">有休消化状況</h1>
    </div>
    <div class="content">
      <div class="no-print">
        <bs-alert
          v-if="registeredMessage"
          :message="registeredMessage"
          color="success"
          closable
          @close="registeredMessage = ''"
        />

        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />
      </div>
      <div class="d-flex m-3">
        <div class="form-check form-check-inline fs-5 mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="target"
            id="all"
            value=""
            v-model="filter.days"
          />
          <label class="form-check-label" for="all">全て</label>
        </div>
        <div class="form-check form-check-inline fs-5 mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="target"
            id="less"
            value="0"
            v-model="filter.days"
          />
          <label class="form-check-label" for="less">消化5日未満</label>
        </div>
        <div class="form-check form-check-inline fs-5 mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="target"
            id="more"
            value="1"
            v-model="filter.days"
          />
          <label class="form-check-label" for="more">消化5日以上</label>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table-scroll">
        <table class="table table-hover sticky_table">
          <thead>
            <tr>
              <th>従業員</th>
              <th>有休付与発生日付</th>
              <th>対象期間</th>
              <th>有休消化日数</th>
              <th>前年繰越残日数</th>
              <th>有休残合計日数</th>
              <th>前年出勤率</th>
            </tr>
          </thead>
          <tbody v-if="filteredEmployee.length > 0">
            <tr v-for="employee in filteredEmployee" :key="employee.id">
              <td>{{ employee.name }}&ensp;({{ employee.identification }})</td>
              <td>{{ employee.last_give_paid_holiday }}</td>
              <td>
                {{ employee.last_give_paid_holiday }}～{{
                  employee.next_give_paid_holiday
                }}&ensp;(勤続{{ employee.length_of_service }}ヶ月)
              </td>
              <td>{{ employee.day_count_of_paid_holiday_used }}</td>
              <td>{{ employee.paid_holiday_current_year }}</td>
              <td>
                {{
                  employee.paid_holiday_current_year +
                  employee.paid_holiday_prev_year
                }}
              </td>
              <td>{{ employee.attendance_rate_last_year }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="14">有休消化情報がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";

export default {
  name: "PaidHolidayList",
  components: { BsAlert },
  data: () => ({
    employees: "",
    user: "",
    registeredMessage: "",
    errorMessage: "",
    today: "",
    statusList: [],
    filter: {
      days: "",
    },
  }),
  computed: {
    filteredEmployee() {
      if (this.filter.days) {
        return this.employees.filter((e) => {
          return this.filter.days == 1
            ? e.day_count_of_paid_holiday_used >= 5
            : e.day_count_of_paid_holiday_used < 5;
        });
      } else {
        return this.employees;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$emit("loading", true);
      client
        .get("/api/system/paid_holiday")
        .then((res) => {
          this.employees = res.data;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
/*tbody tr:hover {*/
/*  cursor: pointer;*/
/*}*/
table.table td {
  border: 1px solid #258 !important;
}
@media print {
  button *,
  br,
  div.no-print *,
  .no-print {
    display: none !important;
  }
  thead * {
    -webkit-print-color-adjust: exact;
  }
  .table-scroll {
    margin: 0;
  }
}
@media screen {
  .table-scroll {
    height: 550px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
}
@page {
  size: A4;
  margin: -10px;
  padding: 0;
}
.text-right {
  text-align: right;
}
</style>
