<template>
  <form class="employee-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/employee')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      従業員管理&emsp;
      <span v-if="isCreate">新規登録</span><span v-else>確認・修正</span>
    </h1>

    <h2 class="mb-3">基本情報</h2>

    <div class="row">
      <div class="col-md-2">
        <label>社員番号</label>
        <input
          type="text"
          class="form-control"
          v-model="employee.identification"
          :class="{ 'is-invalid': !!errors.identification }"
          :disabled="this.$route.params.identification !== 'create'"
        />
        <div v-if="errors.identification" class="invalid-feedback d-block">
          {{ errors.identification[0] }}
        </div>
      </div>
      <div class="col-md-4">
        <label>名前</label>
        <input
          type="text"
          class="form-control"
          v-model="employee.name"
          :class="{ 'is-invalid': !!errors.name }"
        />
        <div v-if="errors.name" class="invalid-feedback d-block">
          {{ errors.name[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>パスワード</label>
        <input
          type="text"
          class="form-control"
          v-model="employee.password"
          placeholder="変更する場合に入力してください。"
          :class="{ 'is-invalid': !!errors.password }"
          pattern="^[0-9a-zA-Z]+$"
        />
        <div v-if="errors.password" class="invalid-feedback d-block">
          {{ errors.password[0] }}
        </div>
      </div>

      <div class="col-md-6">
        <label>入社日</label>
        <input
          type="date"
          class="form-control"
          v-model="employee.join_at"
          :class="{ 'is-invalid': !!errors.join_at }"
        />
        <div v-if="errors.join_at" class="invalid-feedback d-block">
          {{ errors.join_at[0] }}
        </div>
      </div>
      <div class="col-sm-6"></div>
      <div class="col-md-6">
        <label>退社日</label>
        <input
          type="date"
          class="form-control"
          v-model="employee.retirement_at"
          :class="{ 'is-invalid': !!errors.retirement_at }"
        />
        <div v-if="errors.retirement_at" class="invalid-feedback d-block">
          {{ errors.retirement_at[0] }}
        </div>
      </div>
      <div class="col-sm-6">
        <label>最終支給月</label>
        <input
          type="month"
          class="form-control"
          v-model="employee.last_payment_month"
          :class="{ 'is-invalid': !!errors.last_payment_month }"
        />
        <div v-if="errors.last_payment_month" class="invalid-feedback d-block">
          {{ errors.last_payment_month[0] }}
        </div>
      </div>

      <div class="col-md-3">
        <label>部署</label>
        <select2
          :options="departmentSelection"
          v-model="employee.department"
          :class="{ 'is-invalid': !!errors.department }"
        />
        <div v-if="errors.department" class="invalid-feedback d-block">
          {{ errors.department[0] }}
        </div>
      </div>

      <div class="col-md-3">
        <label>契約形態</label>
        <div class="mt-2">
          <div
            class="form-check form-check-inline"
            v-for="type in contractTypes"
            :key="type.value"
          >
            <input
              class="form-check-input"
              type="radio"
              name="contract_type"
              :id="`contract_type-${type.value}`"
              :value="type.value"
              v-model="employee.contract_type"
              :class="{ 'is-invalid': !!errors.contract_type }"
              :disabled="!isCreate"
            />
            <label
              class="form-check-label"
              :for="`contract_type-${type.value}`"
            >
              {{ type.text }}
            </label>
          </div>
          <div v-if="errors.contract_type" class="invalid-feedback d-block">
            {{ errors.contract_type[0] }}
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <label>職種</label>
        <div class="mt-2">
          <div
            class="form-check form-check-inline"
            v-for="occupation in occupations"
            :key="occupation.value"
          >
            <input
              class="form-check-input"
              type="radio"
              name="occupation_id"
              :id="`occupation-${occupation.value}`"
              :value="occupation.value"
              v-model="employee.occupation"
              :class="{ 'is-invalid': !!errors.occupation }"
            />
            <label
              class="form-check-label"
              :for="`occupation-${occupation.value}`"
            >
              {{ occupation.text }}
            </label>
          </div>
          <div v-if="errors.occupation" class="invalid-feedback d-block">
            {{ errors.occupation[0] }}
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <label>権限</label>
        <div class="mt-2">
          <div
            class="form-check form-check-inline"
            v-for="right in accessRights"
            :key="right.value"
          >
            <input
              class="form-check-input"
              type="radio"
              name="access_right"
              :id="`access_right-${right.value}`"
              :value="right.value"
              v-model="employee.access_right"
              :class="{ 'is-invalid': !!errors.access_right }"
            />
            <label
              class="form-check-label"
              :for="`access_right-${right.value}`"
            >
              {{ right.text }}
            </label>
          </div>
          <div v-if="errors.access_right" class="invalid-feedback d-block">
            {{ errors.access_right[0] }}
          </div>
        </div>
      </div>
    </div>

    <h2 class="mb-3">勤怠設定</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="need_overtime_calculation"
            id="need_overtime_calculation"
            :value="true"
            v-model="employee.need_overtime_calculation"
            :class="{ 'is-invalid': !!errors.need_overtime_calculation }"
          />
          <label class="form-check-label" for="need_overtime_calculation">
            残業対象外ユーザー
          </label>
        </div>
        <!-- need_overtime_calculation -->
      </div>
      <div class="col-md-8"></div>

      <div class="col-md-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="need_give_paid_holiday"
            id="need_give_paid_holiday"
            :value="true"
            v-model="employee.need_give_paid_holiday"
            :class="{ 'is-invalid': !!errors.need_give_paid_holiday }"
          />
          <label class="form-check-label" for="need_give_paid_holiday">
            有休計算対象外ユーザー
          </label>
        </div>
        <!-- need_overtime_calculation -->
      </div>
      <div class="col-md-4">
        <label>有休起算日</label>
        <input
          type="date"
          class="form-control"
          v-model="employee.beginning_date_of_paid_holiday"
          :class="{ 'is-invalid': !!errors.beginning_date_of_paid_holiday }"
        />
        <div
          v-if="errors.beginning_date_of_paid_holiday"
          class="invalid-feedback d-block"
        >
          {{ errors.beginning_date_of_paid_holiday[0] }}
        </div>

        <div class="text-muted">
          ※起算日を入社日から変更する場合は入力してください。
        </div>
      </div>
      <div class="col-md-4"></div>

      <div class="col-md-4">
        <label>公休消化数</label>
        <input
          type="number"
          class="form-control"
          :value="employee.day_count_of_holiday_used"
          disabled
        />
      </div>

      <div class="col-md-4">
        <label>公休日数変更</label>
        <input
          type="number"
          class="form-control"
          v-model="employee.day_count_of_holiday_current_year"
          :class="{ 'is-invalid': !!errors.day_count_of_holiday_current_year }"
        />
        <div
          v-if="errors.day_count_of_holiday_current_year"
          class="invalid-feedback d-block"
        >
          {{ errors.day_count_of_holiday_current_year[0] }}
        </div>
      </div>
      <div class="col-md-4">
        <label>公休日数変更理由</label>
        <textarea
          cols="3"
          v-model="employee.reasons_for_reducing_public_holiday"
          :class="{
            'is-invalid': !!errors.reasons_for_reducing_public_holiday,
          }"
          :disabled="
            employee.day_count_of_holiday_current_year ===
            day_count_of_holiday_current_year
          "
        >
        </textarea>
        <div
          v-if="errors.reasons_for_reducing_public_holiday"
          class="invalid-feedback d-block"
        >
          {{ errors.reasons_for_reducing_public_holiday[0] }}
        </div>
      </div>
      <div class="col-12">
        <div class="mb-2">基本就業時間帯</div>
        <div
          v-if="errors.work_shifts?.non_field_errors"
          class="invalid-feedback d-block mb-3"
        >
          <div
            v-for="error in errors.work_shifts.non_field_errors"
            :key="error"
            style="color: inherit"
          >
            {{ error }}
          </div>
        </div>

        <div class="row">
          <div class="col-1 text-center">初期</div>
          <div class="col-md-4 col-6 text-center">始業・終業時間</div>
          <div class="col-md-5 col-3"></div>
        </div>

        <div
          v-for="(shift, index) in employee.work_shifts"
          :key="index"
          class="row"
        >
          <div class="col-1 d-flex justify-content-center align-items-center">
            <input
              class="form-check-input"
              type="radio"
              name="work_shift"
              :id="`work_shift-${index}`"
              :value="true"
              @click="isDefault(index)"
              v-model="shift.is_default"
            />
          </div>
          <div class="col-md-4 col-6">
            <div class="input-group">
              <input
                type="time"
                class="form-control"
                v-model="shift.start_time"
                :class="{
                  'is-invalid':
                    errors.work_shifts && errors.work_shifts[index]?.start_time,
                }"
                :step="300"
              />
              <div class="input-group-text">～</div>
              <input
                type="time"
                class="form-control"
                v-model="shift.end_time"
                :class="{
                  'is-invalid':
                    errors.work_shifts && errors.work_shifts[index]?.end_time,
                }"
              />
            </div>
          </div>
          <div class="col-md-5 col-3">
            <button
              type="button"
              class="btn btn-light me-2"
              @click="addShift"
              :disabled="index !== employee.work_shifts.length - 1"
            >
              <b>＋</b>
            </button>
            <button
              type="button"
              class="btn btn-light ms-2"
              @click="removeShift(index)"
              :disabled="employee.work_shifts.length === 1"
            >
              <b>－</b>
            </button>
          </div>
          <div
            v-if="errors.work_shifts && errors.work_shifts[index]?.start_time"
            class="col-12 invalid-feedback d-block mb-0"
          >
            {{ errors.work_shifts[index].start_time[0] }}
          </div>
          <div
            v-if="errors.work_shifts && errors.work_shifts[index]?.end_time"
            class="col-12 invalid-feedback d-block mb-0"
          >
            {{ errors.work_shifts[index].end_time[0] }}
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-3" v-if="!isCreate">契約形態&emsp;履歴</h2>
    <div v-if="!isCreate" class="mb-3 row">
      <div class="col-sm-8">
        <ul class="list-group">
          <li
            v-for="(c, i) in contract_types"
            :key="c.id"
            class="list-group-item"
            :class="{ 'list-group-item-secondary': c.start_at < today }"
          >
            {{ c.start_at }}&nbsp;～&nbsp;{{ c.end_at }}：{{ c.type_text }}
            <button
              type="button"
              class="btn btn-danger float-end px-3"
              v-if="
                c.start_at !== beforeJoinAt && contract_types.length - 1 === i
              "
              @click.self="deleteContractHistory(c.id)"
            >
              削除
            </button>
          </li>
        </ul>
      </div>
      <div v-if="contractError">
        <span class="text-danger fs-6">{{ contractError }}</span>
      </div>
    </div>
    <h2 class="mb-3">有休残情報</h2>
    <div class="row">
      <div class="col-md-12">
        <div class="m-auto p-auto">
          <table class="paid_holiday_table">
            <tr>
              <th>有休残</th>
              <td>{{ employee["paid_holiday_total"] || 0 }}日</td>
              <th>次回付与日</th>
              <td style="min-width: 100px">
                {{
                  !employee.need_give_paid_holiday
                    ? employee.paid_grant_date
                    : "有休計算対象外"
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-md-4">
        <label>前々年有給休暇残日数</label>
        <input
          type="number"
          class="form-control"
          v-model="employee.paid_holiday_two_year"
          :class="{ 'is-invalid': !!errors.paid_holiday_two_year }"
        />
        <div
          v-if="errors.paid_holiday_two_year"
          class="invalid-feedback d-block"
        >
          {{ errors.paid_holiday_two_year[0] }}
        </div>
      </div>
      <div class="col-md-4">
        <label>前年有給休暇残日数</label>
        <input
          type="number"
          class="form-control"
          v-model="employee.paid_holiday_prev_year"
          :class="{ 'is-invalid': !!errors.paid_holiday_prev_year }"
        />
        <div
          v-if="errors.paid_holiday_prev_year"
          class="invalid-feedback d-block"
        >
          {{ errors.paid_holiday_prev_year[0] }}
        </div>
      </div>
      <div class="col-md-4">
        <label>当年有給休暇残日数</label>
        <input
          type="number"
          class="form-control"
          v-model="employee.paid_holiday_current_year"
          :class="{ 'is-invalid': !!errors.paid_holiday_current_year }"
        />
        <div
          v-if="errors.paid_holiday_current_year"
          class="invalid-feedback d-block"
        >
          {{ errors.paid_holiday_current_year[0] }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/employee')"
        >
          キャンセル
        </button>
        <button
          v-if="!isCreate"
          type="button"
          class="btn btn-danger ms-4"
          @click="destroy"
        >
          &emsp;削除&emsp;
        </button>
      </div>
      <button type="submit" class="btn btn-primary">&emsp;登録&emsp;</button>
    </div>
  </form>
</template>

<script>
import Select2 from "vue3-select2-component";
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "FormView",
  components: { Select2 },
  data: () => ({
    contractTypes: [],
    accessRights: [],
    occupations: [],
    departments: [],
    contract_types: [],
    errors: {},
    today: "",
    not_enrolled: false,
    day_count_of_holiday_current_year: "",
    employee: {
      identification: "",
      name: "",
      gender: "",
      join_at: "",
      retirement_at: "",
      department: "",
      contract_type: "",
      occupation: "",
      give_day_count_of_paid_holiday: "",
      attendance_rate_last_year: "",
      day_count_of_holiday_current_year: "",
      work_shifts: [],
      password: "",
      access_right: "",
      need_overtime_calculation: "",
      last_payment_month: "",
      plane_text_password: "",
      beginning_date_of_paid_holiday: "",
      reasons_for_reducing_public_holiday: "",
      need_give_paid_holiday: false,
    },
    contractError: "",
    beforeJoinAt: null,
  }),
  computed: {
    isCreate() {
      return this.$route.params.identification === "create";
    },
    departmentSelection() {
      let selection = [];
      this.departments.forEach((department) => {
        selection.push({
          id: department.code,
          text: `${department.code}：${department.name}`,
        });
      });

      return selection;
    },
  },
  mounted() {
    this.$emit("loading", true);
    this.today = this.formatDate(new Date());
    client
      .get("/api/employee/employee/" + this.$route.params.identification)
      .then((res) => {
        this.genders = res.data.genders;
        this.contractTypes = res.data.contract_types;
        this.accessRights = res.data.access_rights;
        this.departments = res.data.departments;
        this.occupations = res.data.occupations;

        this.employee = { ...res.data.employee };
        this.contract_types = this.employee.contract_type;
        this.contract_types.sort(function (a, b) {
          return a.start_at > b.start_at ? 1 : -1;
        });
        this.employee.need_overtime_calculation = !this.isCreate
          ? !this.employee.need_overtime_calculation
          : "";
        this.employee.need_give_paid_holiday = !this.isCreate
          ? !this.employee.need_give_paid_holiday
          : false;
        this.employee.contract_type = res.data.contract_type.type;
        if (this.employee.work_shifts.length === 0) {
          this.employee.work_shifts.push({
            index: 0,
            start_time: null,
            end_time: null,
            is_default: true,
          });
        }
        if (this.employee.last_payment_month) {
          this.not_enrolled = true;
          this.employee.last_payment_month =
            this.employee.last_payment_month.slice(0, 4) +
            "-" +
            this.employee.last_payment_month.slice(4, 6);
        }
        this.employee.work_shifts.forEach((shift, index) => {
          this.employee.work_shifts[index].index = index;
        });
        this.employee.password = this.employee.plane_text_password;
        delete this.employee.plane_text_password;
        this.day_count_of_holiday_current_year =
          this.employee.day_count_of_holiday_current_year;
        if (this.isCreate) {
          this.employee.occupation = constants.OCCUPATION_STORE_WORKER;
          this.employee.access_right = constants.ACCESS_RIGHT_USER;
        }
        this.beforeJoinAt = this.employee.join_at;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        this.$emit("loading", false);
      });
  },
  methods: {
    addShift() {
      this.employee.work_shifts.push({
        index: this.employee.work_shifts.length,
        start_time: null,
        end_time: null,
        is_default: false,
      });
    },
    removeShift(removeIndex) {
      this.employee.work_shifts = this.employee.work_shifts.filter(
        (shift, index) => removeIndex !== index
      );
    },
    register() {
      this.$emit("loading", true);
      this.contractError = "";
      let promise = client.put(
        `/api/employee/employee/${this.$route.params.identification}`,
        this.employee
      );
      promise
        .then((res) => {
          this.$emit("loading", false);
          this.$router.push("/employee?registered=" + res.data.identification);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
    destroy() {
      if (
        !confirm(
          "この操作は取り消しできません。\n本当に削除してよろしいですか？"
        )
      ) {
        return;
      }

      this.$emit("loading", true);
      this.contractError = "";
      client
        .delete(`/api/employee/employee/${this.$route.params.identification}`)
        .then((res) => {
          this.$emit("loading", false);
          this.$router.push("/employee?deleted=" + res.data.identification);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
    deleteContractHistory(id) {
      if (
        !confirm(
          "この操作は取り消しできません。\n本当に削除してよろしいですか？"
        )
      ) {
        return;
      }

      this.$emit("loading", true);
      this.contractError = "";
      client
        .delete(`/api/employee/contract_type/delete/${id}`)
        .then(() => {
          this.$emit("loading", false);
          this.$router.push("/employee?deleted=contract");
        })
        .catch((error) => {
          console.error(error);
          this.contractError = error.response.data.message;
          this.$emit("loading", false);
        });
    },
    isDefault(index) {
      for (let i = 0; i < this.employee.work_shifts.length; i++) {
        this.employee.work_shifts[i].is_default = i === index;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
table.paid_holiday_table {
  table-layout: fixed;

  th {
    padding: 0.5rem;
    border: solid 1px #333;
    background-color: #ccc;
  }
  td {
    padding: 0.5rem;
    border: solid 1px #333;
  }
}
</style>
