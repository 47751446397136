import { createApp } from "vue";
import "bootstrap";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/axios";

const vue = createApp(App).use(store).use(router).mount("#app");

// メインプロセスからホスト名を受信
if (window.electronAPI) {
  window.electronAPI.setHostname((_event, value) => {
    console.log("setHostname", value);
    store.dispatch("client/setHostname", value);
  });
}

export default vue;
