<template>
  <div class="d-flex justify-content-sm-center mt-3" v-if="totalCount">
    <div class="btn-group" role="group" aria-label="Basic outlined example">
      <button
        type="button"
        class="btn btn-outline-primary"
        :disabled="currentPage === 1"
        @click="search(currentPage - 1)"
      >
        <span class="material-icons text-primary mt-1">arrow_back_ios</span>
      </button>
      <button
        type="button"
        class="btn"
        :class="currentPage === 1 ? 'btn-primary' : 'btn-outline-primary'"
        @click="search(1)"
      >
        {{ 1 }}
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        v-if="pageLength > 5 && currentPage > 3"
        disabled
      >
        <span class="material-icons text-primary mt-1">more_horiz</span>
      </button>
      <button
        type="button"
        class="btn"
        v-for="i in numbers"
        :key="i"
        :class="i === currentPage ? 'btn-primary' : 'btn-outline-primary'"
        @click="search(i)"
      >
        {{ i }}
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        v-if="pageLength > 5 && currentPage < pageLength - 2"
        disabled
      >
        <span class="material-icons text-primary mt-1">more_horiz</span>
      </button>
      <button
        type="button"
        class="btn"
        :class="
          pageLength === currentPage ? 'btn-primary' : 'btn-outline-primary'
        "
        v-if="pageLength > 1"
        @click="search(pageLength)"
      >
        {{ pageLength }}
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        :disabled="currentPage === pageLength"
        @click="search(currentPage + 1)"
      >
        <span class="material-icons text-primary mt-1">arrow_forward_ios</span>
      </button>
    </div>
    <div class="input-group mx-2" style="max-width: 110px">
      <input
        type="number"
        class="form-control border-primary text-primary"
        v-model="editPageCount"
        @change="search(editPageCount)"
      />
      <span class="input-group-text material-icons text-primary border-primary"
        >arrow_forward_ios</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginateButton",
  props: {
    totalCount: { type: Number },
    currentPage: { type: Number },
    pageCount: { type: Number, default: 100 },
  },
  data() {
    return {
      editPageCount: 1,
    };
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalCount / this.pageCount);
    },
    numbers() {
      const array = [];
      let s = 0;
      let e = 0;
      if (this.pageLength > 5) {
        s =
          this.currentPage <= 3
            ? 2
            : this.currentPage > this.pageLength - 2
            ? this.pageLength - 3
            : this.currentPage - 1;
        e =
          this.currentPage >= this.pageLength - 1
            ? this.pageLength - 1
            : this.currentPage < 3 && this.pageLength > 4
            ? 4
            : this.currentPage + 1;
      } else {
        s = 2;
        e = this.pageLength - 1;
      }
      for (let i = s; i <= e; ++i)
        if (i) {
          array.push(i);
        }
      return array;
    },
  },
  watch: {
    currentPage(value) {
      this.editPageCount = value;
    },
  },
  methods: {
    search(page = 1) {
      if (!page || isNaN(page) || page <= 0) {
        this.editPageCount = 1;
      } else if (page > this.pageLength && this.pageLength) {
        this.editPageCount = this.pageLength;
      } else {
        this.editPageCount = page;
      }
      this.$emit("search", this.editPageCount);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
