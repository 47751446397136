<template>
  <button
    class="btn btn-secondary mb-3 d-flex align-items-center"
    @click="$router.push('/timecard_summary')"
  >
    <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
  </button>
  <h1>連続休日日数確認</h1>
  <div class="d-flex justify-content-sm-between m-3">
    <input
      type="month"
      class="form-control"
      style="width: 350px; font-size: larger"
      v-model="month"
    />
    <button class="btn btn-primary d-flex align-items-center" @click="download">
      <span class="material-icons text-white me-1">download</span>
      ダウンロード
    </button>
  </div>
  <div class="table-scroll">
    <table class="table table-hover sticky_table" id="summary">
      <thead>
        <tr>
          <th>従業員ID</th>
          <th>名前</th>
          <th>連続日数</th>
          <th>開始日～終了日</th>
        </tr>
      </thead>

      <tbody v-if="offCountList.length > 0">
        <tr
          v-for="element in offCountList"
          :key="element.id"
          @click="
            $router.push(
              `/timecard?month=${String(month).replace('-', '')}&employee=${
                element.summary.employee.identification
              }&from=summary`
            )
          "
        >
          <td>{{ element.summary.employee.identification }}</td>
          <td>{{ element.summary.employee.name }}</td>
          <td>{{ element.day_count || 0 }}日</td>
          <td>{{ element.start_date }}～{{ element.end_date }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="14">勤怠情報がありません。</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "OffCountList",
  data: () => ({
    errors: {},
    offCountList: [],
    month: "",
    tardy_and_early_reset: true,
  }),
  watch: {
    month() {
      this.init();
    },
  },
  mounted() {
    this.month = `${this.$route.query.month}`;
    this.init();
  },
  methods: {
    init() {
      const params = { month: this.month };
      client
        .get("/api/timecard/off_count", { params: params })
        .then((res) => {
          this.$emit("loading", false);
          this.offCountList = res.data;
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    formatSeconds(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    download() {
      this.$emit("loading", true);
      client({
        url: "/api/timecard/export_off_count",
        method: "GET",
        responseType: "blob",
        params: { month: this.month },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `休日4日以上_${this.month}.csv`);
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

@media screen {
  .table-scroll {
    height: 450px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
}
</style>
