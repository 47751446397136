<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">勤怠管理&emsp;確定</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />
      <bs-alert
        :message="message"
        color="danger"
        v-for="(message, index) in messages"
        :key="index"
      />

      <div
        v-if="noWorkingSummary && noWorkingSummary.length > 0"
        class="card mb-3"
      >
        <div class="card-header bg-opacity-25 bg-danger">
          <p class="mb-0" style="color: #852028">
            勤務実績なし&ensp;かつ&ensp;勤怠承認未完了の従業員です。確認してください。（対象者{{
              noWorkingSummary.length
            }}人）
          </p>
        </div>
        <div class="card-body p-0">
          <div class="overflow-scroll" style="max-height: 45vh; width: 100%">
            <p v-for="summary in noWorkingSummary" :key="summary.employee_id">
              ・{{ summary.employee_name }}({{ summary.employee_id }})
            </p>
          </div>
        </div>
        <div class="card-footer" v-if="messages && messages.length === 0">
          <div class="d-flex justify-content-center">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="check_timecard"
                id="check_timecard"
                value="1"
                v-model="check_timecard"
              />
              <label class="form-check-label" for="check_timecard"
                >未承認の勤怠実績なしの勤怠の確認が完了しました</label
              >
            </div>
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmed"
              :disabled="!check_timecard"
            >
              勤怠一括確定
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <button
          type="button"
          class="btn btn-secondary px-3"
          @click="closeModal"
        >
          キャンセル
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";

export default {
  name: "ConfirmFormView",
  components: { BsAlert },
  props: {
    messages: {
      required: false,
      type: Array,
    },
    noWorkingSummary: {
      required: false,
      type: Array,
    },
  },
  data: () => ({
    errors: {},
    errorMessage: "",
    check_timecard: false,
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmed() {
      this.$emit("approved");
    },
  },
};
</script>

<style scoped></style>
