<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">従業員管理&emsp;一括登録</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        message="ファイルの内容で上書きしなおします。注意してください。"
        color="warning"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />

      <form class="employee-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>

        <div class="row">
          <div class="col-6 mb-3"></div>
          <div class="col-8 mb-3">
            <input
              class="form-control"
              type="file"
              @change="form.file = $event.target.files[0]"
            />
          </div>
          <div class="col-12 mb-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="has_header"
                id="has_header"
                value="1"
                v-model="form.has_header"
              />
              <label class="form-check-label" for="has_header"
                >1行目にタイトルがある</label
              >
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button class="btn btn-primary">登録</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "EmployeeImportView",
  components: { BsAlert },
  data: () => ({
    errors: {},
    form: { file: null, has_header: false },
    errorMessage: "",
  }),
  methods: {
    register() {
      const url = "/api/employee/employee/import";
      const data = new FormData();
      data.append("file", this.form.file);
      data.append("has_header", this.form.has_header ? true : "");
      const headers = { "content-type": "multipart/form-data" };
      client
        .post(url, data, { headers })
        .then(() => {
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.error.replace(
              /\n/g,
              "<br>"
            );
          }
          this.$emit("failed");
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
