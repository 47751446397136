<template>
  <form class="timecard-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      有給休暇申請&emsp;
      <span>新規登録</span>
    </h1>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <div class="row mb-3">
      <label class="mb-0">申請種別</label>
      <div class="btn-group col-md-12">
        <div
          class="btn-group col-md-1"
          role="group"
          aria-label="kind"
          v-for="(value, key) in kinds"
          :key="key"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.kind }"
            name="kind"
            :id="`kind-${key}`"
            :value="key"
            v-model="form.kind"
            disabled
          />
          <label class="btn btn-outline-success py-2" :for="`kind-${key}`">
            {{ value }}
          </label>
        </div>
      </div>
      <div v-if="errors.kind" class="invalid-feedback d-block">
        {{ errors.kind[0] }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>取得(予定)日</label>
        <input
          type="date"
          class="form-control"
          v-model="form.target_date"
          :class="{ 'is-invalid': !!errors.target_date }"
          :min="today"
        />
        <div v-if="errors.target_date" class="invalid-feedback d-block">
          {{ errors.target_date[0] }}
        </div>
      </div>
      <div class="col-md-6">
        <label>取得(予定)日数</label>
        <input
          type="number"
          class="form-control"
          v-model="form.days"
          :class="{ 'is-invalid': !!errors.days }"
          min="1"
        />
        <div v-if="errors.days" class="invalid-feedback d-block">
          {{ errors.days[0] }}
        </div>
        <p>有休残: {{ employee.paid_holiday_current_year }}日</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>申請理由</label>
        <textarea
          cols="2"
          v-model="form.reason"
          :class="{ 'is-invalid': !!errors.reason }"
        >
        </textarea>
        <div v-if="errors.reason" class="invalid-feedback d-block">
          {{ errors.reason[0] }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/')"
        >
          キャンセル
        </button>
      </div>
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="[1, 2].includes(form.status)"
      >
        &emsp;登録&emsp;
      </button>
    </div>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "FormView",
  components: { BsAlert },
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: "",
    today: "",
    request: "",
    kinds: {},
    form: {
      kind: "",
      target_date: "",
      days: 1,
      reason: "",
    },
  }),
  mounted() {
    this.$emit("loading", true);
    this.today = this.formatDate(new Date());
    client
      .get("/api/request/paid_holiday/" + this.$route.params.id)
      .then((res) => {
        this.employee = res.data.employee;
        this.request = res.data.request;
        this.kinds = res.data.kinds;
        this.form = { ...res.data.request };
        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.today = this.formatDate(date);
        if ([1, 2].includes(this.form.status)) {
          this.errorMessage = "未承認・承認済の申請は編集できません。";
        }
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
          this.errorMessage = error.response.data.detail;
          this.form.date = error.response.data.timecard.date;
        }
        this.$emit("loading", false);
      });
  },
  methods: {
    register() {
      this.$emit("loading", true);
      let requestId = this.$route.params.id;
      let promise =
        requestId != 0
          ? client.put(
              `/api/request/paid_holiday/${this.$route.params.id}`,
              this.form
            )
          : client.post("/api/request/paid_holiday", this.form);

      promise
        .then((res) => {
          this.$emit("loading", false);
          this.$router.push("/request_list?registered=" + res.data.kind_text);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
    formatDate(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea:focus {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
</style>
