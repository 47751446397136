import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./modules/user";
import client from "./modules/client";
import search from "@/store/modules/search";

export default createStore({
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { user, client, search },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
