<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">勤怠管理&emsp;確定</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        :message="`${summary.start_date}～${summary.end_date}の勤怠を確定します。\n確定後は打刻と勤怠の編集ができなくなります。`"
        color="danger"
        style="white-space: pre-line"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />

      <form class="confirm-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <div class="row">
          <div class="col-12 mb-3" v-if="timecards && timecards.length > 0">
            <p class="px-3 text-danger">
              打刻・休日設定がされていない勤怠が{{
                timecards.length
              }}日分あります。確定後、空欄は欠勤になります。
            </p>
          </div>
          <div class="col-sm-12">
            <ul class="list-group list-group-flush">
              <li
                v-for="(value, key) in timecards"
                :key="key"
                class="list-group-item pb-0"
              >
                <p>
                  {{ value.date }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 mb-3"
            v-if="error_timecards && error_timecards.length > 0"
          >
            <p class="px-3 text-danger">
              勤怠エラーが{{ error_timecards.length }}日分あります。
            </p>
          </div>
          <div class="col-sm-12">
            <ul class="list-group list-group-flush">
              <li
                v-for="(value, key) in error_timecards"
                :key="key"
                class="list-group-item pb-0"
              >
                <p>
                  {{ value.date }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" v-if="summary.status == 2">
            <ul class="list-group">
              <li class="list-group-item list-group-item-secondary">
                差し戻し理由
              </li>
              <li class="list-group-item">
                <p style="white-space: pre-line">
                  {{ summary.send_back_reason }}
                </p>
              </li>
            </ul>
          </div>
          <div class="col-12 m-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="has_difference"
                id="has_difference"
                v-model="form.has_difference"
                :class="{ is_invalid: !!errors.has_difference }"
              />
              <label class="form-check-label" for="has_difference"
                >エラー報告書の提出</label
              >
            </div>
            <div v-if="errors.has_difference" class="invalid-feedback d-block">
              {{ errors.has_difference[0] }}
            </div>
          </div>
          <div class="col-12 m-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="checked"
                id="checked"
                v-model="form.checked"
              />
              <label class="form-check-label" for="checked"
                >勤怠の確認をしました</label
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <button
            type="button"
            class="btn btn-secondary px-3"
            @click="closeModal"
          >
            キャンセル
          </button>
          <button
            type="submit"
            class="btn px-3"
            :class="form.checked ? 'btn-primary' : 'btn-secondary'"
            :disabled="!form.checked"
          >
            確定
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "ConfirmFormView",
  components: { BsAlert },
  props: {
    summary: {
      required: true,
      type: Object,
    },
    isThisMonth: {
      type: Boolean,
    },
  },
  data: () => ({
    errors: {},
    timecard: {},
    timecards: [],
    error_timecards: [],
    form: { has_difference: false, checked: false },
    errorMessage: "",
    mode: "validation",
  }),
  watch: {
    isThisMonth(value) {
      this.mode = value && this.timecard ? "validation" : "register";
    },
    timecard(value) {
      this.mode = value && this.isThisMonth ? "validation" : "register";
    },
    summary(value) {
      this.$emit("loading", true);
      this.errors = {};
      this.form.has_difference = false;
      this.form.checked = false;

      client
        .get(`/api/timecard/absence?summary=${value.id}`)
        .then((res) => {
          this.timecard = res.data.timecard;
          this.timecards = res.data.timecards;
          this.error_timecards = res.data.error_timecards;
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    register() {
      this.$emit("loading", true);

      let promise = client.put(
        `/api/timecard/timecard/confirm/${this.summary.id}?mode=${this.mode}`,
        this.form
      );

      promise
        .then(() => {
          if (this.mode === "validation") {
            this.$emit("close");
            this.$router.push(
              `/timecard/${0}?mode=summary&difference=${Number(
                this.form.has_difference
              )}`
            );
          } else {
            this.$emit("registered");
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            if (this.errors.detail) {
              this.$emit("close", this.errors.detail);
            }
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
