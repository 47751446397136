<template>
  <div class="department-list">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
    </button>
    <h1>組織管理</h1>

    <div class="content">
      <bs-alert
        v-if="registeredMessage"
        :message="registeredMessage"
        color="success"
        closable
        @close="registeredMessage = ''"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        @close="errorMessage = ''"
      />

      <div class="my-3 d-flex">
        <div class="form-check form-check-inline my-auto">
          <input type="checkbox" id="is_show" v-model="is_show" />
          <label for="is_show">全件表示</label>
        </div>
        <div class="ms-auto d-flex">
          <button
            type="button"
            class="btn btn-primary me-3 d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_import"
          >
            <span class="material-icons text-white me-1">upload_file</span>
            一括登録
          </button>
          <button
            type="button"
            class="btn btn-primary d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_form"
            @click="editId = 0"
          >
            <span class="material-icons text-white me-1">add</span>
            新規登録
          </button>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>部署コード</th>
            <th>部署名</th>
            <th>親部署名</th>
            <th>承認者</th>
            <th>表示</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody v-if="departments.length > 0">
          <tr v-for="department in filteredDepartments" :key="department.code">
            <td>{{ department.code }}</td>
            <td>{{ department.name }}</td>
            <td>
              {{
                department.parent_department
                  ? department.parent_department_name
                  : "－"
              }}
            </td>
            <td>
              {{ department.authorizer ? department.authorizer_name : "－" }}
            </td>
            <td class="text-center">
              <span v-if="department.valid" class="material-icons me-1"
                >radio_button_unchecked</span
              >
            </td>
            <td class="text-center">
              <button
                class="btn btn-sm btn-primary mx-3"
                data-bs-toggle="modal"
                data-bs-target="#modal_form"
                @click="editId = department.code"
              >
                編集
              </button>
              <button
                class="btn btn-sm btn-danger mx-3"
                @click="toggleValid(department.code, department.valid)"
              >
                {{ department.valid ? "非表示" : "表示" }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">登録がありません。</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="modal fade"
      id="modal_form"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_form"
    >
      <div class="modal-dialog modal-dialog-centered">
        <department-form-view :id="editId" @registered="registered" />
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="modal_import"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_import"
    >
      <div class="modal-dialog modal-dialog-centered">
        <department-import-view @registered="imported" />
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import DepartmentFormView from "./FormView";
import DepartmentImportView from "@/views/Department/ImportView.vue";
import BsAlert from "@/components/Alert.vue";
import * as bootstrap from "bootstrap";
import * as constants from "@/constants";

export default {
  name: "DepartmentListView",
  components: { BsAlert, DepartmentFormView, DepartmentImportView },
  data: () => ({
    departments: [],
    is_show: true,
    editId: -1,
    registeredMessage: "",
    errorMessage: "",
    authorizers: [],
  }),
  mounted() {
    this.init();
  },
  computed: {
    filteredDepartments() {
      return this.is_show
        ? this.departments
        : this.departments.filter((department) => department.valid);
    },
  },
  methods: {
    init() {
      this.$emit("loading", true);
      let url = "/api/employee/department";
      client
        .get(url)
        .then((response) => {
          this.departments = response.data;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    registered() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_form);
      modal.hide();
      this.registeredMessage = "組織情報を登録しました。";
      this.editId = -1;
      this.init();
    },
    imported() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_import);
      modal.hide();
      this.registeredMessage = "部署の一括登録が完了しました。";
      this.init(true);
    },
    toggleValid(id, valid) {
      if (
        !confirm(`本当に${valid ? "非表示" : "表示"}にしてよろしいですか？`)
      ) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete(`/api/employee/department/${id}`)
        .then(() => {
          this.registeredMessage = `${valid ? "非表示" : "表示"}にしました。`;
          this.$emit("loading", false);
          this.init();
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
.content {
  width: 800px;
  margin: auto;
}
button.btn-danger {
  width: 60px;
}
</style>
