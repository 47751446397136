const state = {
  summary: {},
  request: {},
  timecard: {},
  employee: {},
};

const mutations = {
  setSummary(state, value) {
    state.summary = { ...value };
  },
  setRequest(state, value) {
    state.request = { ...value };
  },
  setTimecard(state, value) {
    state.timecard = { ...value };
  },
  setEmployee(state, value) {
    state.employee = { ...value };
  },
};

const getters = {
  summary: (state) => {
    return state.summary;
  },
  request: (state) => {
    return state.request;
  },
  timecard: (state) => {
    return state.timecard;
  },
  employee: (state) => {
    return state.employee;
  },
};

const actions = {
  setSummary({ commit }, value) {
    commit("setSummary", value);
  },
  setRequest({ commit }, value) {
    commit("setRequest", value);
  },
  setTimecard({ commit }, value) {
    commit("setTimecard", value);
  },
  setEmployee({ commit }, value) {
    commit("setEmployee", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
