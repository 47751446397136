<template>
  <div>
    <p class="h3">エラーが発生しました。</p>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import * as constants from "../constants";

export default {
  name: "ErrorPage",
  mounted() {
    this.$emit("loading", false); // 他画面で表示したローディングを非表示にする
  },
  computed: {
    message() {
      let statusCode = Number(this.$route.query.status) || 500;
      console.log(statusCode);
      if (statusCode === constants.HTTP_RESPONSE_FORBIDDEN) {
        return "ご使用のPCまたはネットワークでは本システムはご利用いただけません。";
      }

      return "システムエラーが発生しました。恐れ入りますがしばらく時間をおいて再度操作してください。";
    },
  },
};
</script>

<style scoped></style>
