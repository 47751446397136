<template>
  <div class="employee-list">
    <div class="no-print">
      <button
        v-if="$route.query.from === 'summary'"
        class="btn btn-secondary mb-3 d-flex align-items-center"
        @click="backToSummary"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <button
        v-else
        class="btn btn-secondary mb-3 d-flex align-items-center"
        onclick="history.back();"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <h1 class="mb-3">勤怠管理&emsp;一覧</h1>
    </div>
    <div class="print-area">
      <p v-if="filter.month">
        {{ filter.month.slice(0, 4) }}年{{
          filter.month.slice(6, 7)
        }}月勤怠&emsp;{{ employee.name }}
      </p>
    </div>
    <div class="content">
      <div class="no-print">
        <bs-alert
          v-if="registeredMessage"
          :message="registeredMessage"
          color="success"
          closable
          @close="registeredMessage = ''"
        />

        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />

        <div class="d-flex flex-sm-wrap">
          <div class="d-flex mb-3">
            <select
              v-model="filter.is_all"
              class="form-select mx-2"
              style="min-width: 100px; font-size: larger"
            >
              <option value="1">全件</option>
              <option value="0">打刻漏れ</option>
            </select>
          </div>
          <div class="d-flex mb-3">
            <button class="btn btn-link" @click="toPrevMonth">
              <span class="material-icons me-1">arrow_back_ios</span>
            </button>
            <input
              type="month"
              class="form-control mx-2"
              style="font-size: larger"
              v-model="filter.month"
              :max="maxMonth"
            />
            <button class="btn btn-link" @click="toNextMonth">
              <span class="material-icons me-1">arrow_forward_ios</span>
            </button>
          </div>
          <div class="d-flex mb-3">
            <select2
              v-model="filter.employee"
              class="mx-2"
              style="min-width: 300px; font-size: larger"
              :options="employeeSelection"
              :settings="employeeSelectSettings"
              v-if="employees.length > 1"
            ></select2>
          </div>
          <div class="d-flex mb-3">
            <select
              v-model="filter.contract"
              class="form-select mx-2"
              style="font-size: larger"
              v-if="summary.length > 1"
            >
              <option v-for="text in summary" :key="text.id" :value="text">
                {{ text.contract_type.type_text }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-end mb-3">
          <div class="ms-auto d-flex">
            <button
              type="button"
              class="btn me-3 d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#modal_confirm"
              :class="enableConfirmButton ? 'btn-primary' : 'btn-secondary'"
              :disabled="!enableConfirmButton"
              v-if="
                summary.length > 0 &&
                (filter.employee == user.identification ||
                  user.access_right == 1)
              "
            >
              <span class="material-icons text-white me-1">
                check_circle_outline
              </span>
              確定
            </button>
            <button
              type="button"
              class="btn me-3 d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#modal_approve"
              :class="enableApproveButton ? 'btn-primary' : 'btn-secondary'"
              :disabled="!enableApproveButton"
              v-if="
                summary.length > 0 &&
                filter.employee != user.identification &&
                user.access_right != 3
              "
            >
              <span class="material-icons text-white me-1">verified</span>
              承認
            </button>
            <button
              class="btn btn-primary me-3 d-flex align-items-center"
              @click="
                $router.replace(`/timecard/bulk_update/${filter.employee}`)
              "
              v-if="user.access_right == 1"
            >
              <span class="material-icons text-white me-1">download</span>
              一括登録
            </button>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle me-3 d-flex align-items-center"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              >
                <span class="material-icons text-white me-1">download</span>
                ダウンロード
              </button>
              <ul class="dropdown-menu dropdown-menu-lg-end">
                <li>
                  <button
                    type="button"
                    class="dropdown-item"
                    @click="exportErrorFormat"
                  >
                    エラー報告書
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item"
                    @click="exportMyList"
                  >
                    出勤簿PDF
                  </button>
                </li>
                <li
                  v-if="
                    user.access_right == 1 &&
                    employee_reserve &&
                    Object.keys(employee_reserve).length
                  "
                >
                  <button
                    type="button"
                    class="dropdown-item"
                    @click="exportAttendanceRecord(1)"
                  >
                    出勤簿PDF(全社員)
                  </button>
                </li>
                <li
                  v-if="
                    user.access_right == 1 &&
                    staff_reserve &&
                    Object.keys(staff_reserve).length
                  "
                >
                  <button
                    type="button"
                    class="dropdown-item"
                    @click="exportAttendanceRecord(2)"
                  >
                    出勤簿PDF(全パート)
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item"
                    @click="
                      exportTimecard(
                        filter.contract.id ? filter.contract.id : 0
                      )
                    "
                  >
                    出勤簿CSV出力
                  </button>
                </li>
              </ul>
            </div>
            <div
              v-if="
                summary.length > 0 &&
                filter &&
                filter.contract &&
                filter.contract['status_text']
              "
              class="me-3"
            >
              <span
                class="badge bg-secondary rounded-pill fs-6 p-3 text-dark bg-opacity-25"
                >{{ filter.contract.status_text }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <table id="summary" class="w-100 mb-3">
        <tr>
          <th>出勤日数</th>
          <th>実労働時間計</th>
          <th>残業時間計</th>
          <th>遅刻早退時間計</th>
          <th>深夜時間計</th>
          <th>欠勤日数</th>
          <th>公休日数</th>
          <th>特別休暇日数</th>
          <th>有給休暇日数</th>
        </tr>
        <tr>
          <td>{{ calcWorkDays }}日</td>
          <td>{{ calcWorkHours }}時間</td>
          <td>{{ calcOvertimeHours }}時間</td>
          <td>{{ calcTardyHours }}時間</td>
          <td>{{ calcMidnightHours }}時間</td>
          <td>{{ calcAbsentDays }}日</td>
          <td>
            <span
              v-if="
                this.filter.contract.contract_type &&
                this.filter.contract.contract_type.type == 1
              "
              >{{ calcPublicHoliday }}日</span
            >
          </td>
          <td>{{ calcSpecialVacation }}日</td>
          <td>{{ calcPaidHoliday }}日</td>
        </tr>
        <tr class="no-print">
          <th>公休残</th>
          <th>有休残</th>
          <th colspan="7"></th>
        </tr>
        <tr class="no-print">
          <td>
            <template
              v-if="
                filter.contract.contract_type &&
                filter.contract.contract_type.type == 1 &&
                filter.month === maxMonth
              "
            >
              <span
                :class="{
                  'text-danger': employee.day_count_of_public_holiday < 0,
                }"
                >{{ employee.day_count_of_public_holiday }}日</span
              >
            </template>
          </td>
          <td>
            <template
              v-if="
                filter.month === maxMonth && employee.need_give_paid_holiday
              "
            >
              {{ employee.day_count_of_paid_holiday }}日
            </template>
          </td>
          <td colspan="7">
            <span v-if="employee.need_give_paid_holiday"
              >有休付与日({{ employee.paid_grant_date }})</span
            >
            <span v-else>&ensp;</span>
          </td>
        </tr>
      </table>
      <div class="table-scroll">
        <table class="table table-hover sticky_table">
          <thead>
            <tr>
              <th style="width: 7rem">就業日</th>
              <th style="width: 15rem">開始～終了(実就業時間)</th>
              <th class="no-print" style="width: 15rem">
                開始～終了(就業予定時間)
              </th>
              <th style="width: 7rem">残業時間</th>
              <th>残業理由</th>
              <th class="no-print" style="width: 8rem">遅刻早退時間</th>
              <th class="no-print">遅刻早退理由</th>
              <th class="no-print">コメント</th>
              <th class="no-print" style="width: 4rem" v-if="user.is_superuser">
                履歴
              </th>
            </tr>
          </thead>
          <tbody v-if="summary.length >= 1 && errorTimecards.length > 0">
            <tr
              v-for="(timecard, index) in errorTimecards"
              :key="timecard.id"
              @click="editTimecard(timecard.id, timecard.kind)"
              :style="{
                backgroundColor: selectColor(
                  timecard.kind,
                  timecard.holiday_and_weekday
                ),
              }"
              class="td-nowrap"
              :class="{ 'cursor-none': timecard.kind == 0 }"
            >
              <td
                class="text-center td-nowrap"
                :class="{ 'text-l': timecard.date == today }"
              >
                {{ timecard.date_and_weekday }}
              </td>
              <td
                v-if="
                  (timecard.kind == 1 ||
                    timecard.kind == 2 ||
                    timecard.kind == 8) &&
                  timecard.work_start_at
                "
                class="fw-bold td-nowrap"
                :class="{ 'text-danger': timecard.has_history }"
              >
                {{ timecard.work_start_time }}～{{ timecard.work_finish_time }}
                (
                {{ formatSeconds(timecard.normal_work_minutes) }}
                )
                <detail-tooltip
                  class="float-end"
                  :index="index"
                  :kind="timecard.kind"
                  :related_date="timecard.related_date"
                  :request="timecard.request"
                />
              </td>
              <td v-else-if="timecard.kind === kind.not_enrolled"></td>
              <td v-else>
                {{ timecard.kind_text
                }}<span v-if="timecard.kind == 5"
                  >({{ timecard.special_kind_text }})</span
                >
                <detail-tooltip
                  class="float-end"
                  :index="index"
                  :kind="timecard.kind"
                  :related_date="timecard.related_date"
                  :request="timecard.request"
                />
              </td>
              <td
                v-if="
                  (timecard.kind === kind.work_on_weekdays ||
                    timecard.kind === kind.work_on_holiday) &&
                  timecard.shifts_start_time
                "
                class="no-print td-nowrap"
              >
                {{
                  timecard.shifts_start_time
                    ? timecard.shifts_start_time.slice(0, 5)
                    : ""
                }}～{{
                  timecard.shifts_end_time
                    ? timecard.shifts_end_time.slice(0, 5)
                    : ""
                }}
              </td>
              <td v-else class="no-print"></td>
              <td
                v-if="
                  (timecard.kind === kind.work_on_weekdays ||
                    timecard.kind === kind.work_on_holiday) &&
                  timecard.work_start_at &&
                  timecard.work_end_at
                "
                class="text-center"
              >
                {{
                  formatSeconds(
                    timecard.overtime_minutes + timecard.midnight_minutes
                  )
                }}
              </td>
              <td v-else></td>
              <td
                v-if="
                  timecard.kind === kind.work_on_weekdays ||
                  timecard.kind === kind.work_on_holiday
                "
                class="no-print text-overflow"
              >
                {{ timecard.overtime_reason }}
                <reset-tooltip
                  class="float-end"
                  :index="index"
                  :type="1"
                  :reasons="timecard.overtime_reset_reasons"
                  :minutes="timecard.overtime_reset_minutes"
                />
              </td>
              <td v-else class="no-print"></td>
              <td
                v-if="
                  (timecard.kind === kind.work_on_weekdays ||
                    timecard.kind === kind.work_on_holiday) &&
                  timecard.work_start_at &&
                  timecard.work_end_at
                "
                class="text-center"
              >
                {{ formatSeconds(timecard.tardy_and_early_leaving_minutes) }}
              </td>
              <td v-else></td>
              <td class="no-print text-overflow">
                {{ timecard.tardy_and_early_leaving_reason }}
                <reset-tooltip
                  class="float-end"
                  :index="index"
                  :type="2"
                  :reasons="timecard.tardy_and_early_leaving_reset_reasons"
                  :minutes="timecard.tardy_and_early_leaving_reset_minutes"
                />
              </td>
              <td class="no-print text-overflow">{{ timecard.comment }}</td>
              <td
                v-if="timecard.kind == 0 && user.is_superuser"
                class="no-print"
              ></td>
              <td
                class="no-print text-center"
                v-else-if="user.is_superuser && timecard.latest_history"
                @click.stop="showHistory(timecard.id)"
              >
                <span class="material-icons" v-if="timecard.latest_history">
                  change_history
                </span>
              </td>
              <td class="no-print" v-else-if="user.is_superuser"></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">登録がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="modal_confirm"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_confirm"
    >
      <div class="modal-dialog modal-dialog-centered">
        <confirm-form-view
          :summary="filter.contract"
          :isThisMonth="isThisMonth"
          @registered="confirmed"
          @close="closeConfirmModal"
        />
      </div>
    </div>
    <div
      class="modal fade modal-lg"
      id="modal_approve"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_approve"
    >
      <div class="modal-dialog modal-dialog-centered">
        <approve-form-view
          :summary="filter.contract"
          @registered="approved"
          @close="closeApproveModal"
        />
      </div>
    </div>
    <transition name="fade">
      <div v-if="show_history_modal">
        <div
          class="modal history_modal"
          @click.self="show_history_modal = false"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title">勤怠変更履歴&emsp;確認</h6>
                <button
                  type="button"
                  class="btn-close"
                  @click="show_history_modal = false"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div v-if="histories.length > 0">
                      <div v-for="(value, key) in histories" :key="key">
                        <div class="card">
                          <div class="card-header">
                            {{ value.created_at }}&emsp;({{ value.reason }})
                          </div>
                          <div class="card-body row">
                            <p class="comment-area col-sm-6 border-end">
                              【変更前】<br />{{ value.before }}
                            </p>
                            <p class="comment-area col-sm-6">
                              【変更後】<br />{{ value.after }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul class="list-group" v-else>
                      <li class="list-group-item">
                        <p>変更履歴がありません。</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <button
                    type="button"
                    class="btn btn-secondary mt-2"
                    data-bs-dismiss="modal"
                    @click.self="show_history_modal = false"
                  >
                    閉じる
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop show"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import Select2 from "vue3-select2-component";
import client from "@/plugins/axios";
import * as constants from "@/constants";
import ConfirmFormView from "@/views/Timecard/ConfirmForm";
import ApproveFormView from "@/views/Timecard/ApproveForm";
import * as bootstrap from "bootstrap";
import html2pdf from "html2pdf.js";
import DetailTooltip from "@/views/Timecard/DetailTooltip";
import ResetTooltip from "@/views/Timecard/ResetTooltip";

export default {
  name: "TimecardList",
  components: {
    ConfirmFormView,
    BsAlert,
    Select2,
    ApproveFormView,
    DetailTooltip,
    ResetTooltip,
  },
  data: () => ({
    employee: "",
    registeredMessage: "",
    errorMessage: "",
    today: "",
    summary: [],
    histories: [],
    user: {},
    show_history_modal: false,
    filter: {
      employee: "",
      month: "",
      is_all: 1,
      contract: {},
    },
    employees: [],
    employeeSelectSettings: { language: "ja", allowClear: true },
    timecards: [],
    kind: {
      not_enrolled: 0,
      work_on_weekdays: 1,
      work_on_holiday: 2,
      public_holiday: 3,
      paid_holiday: 4,
      special_holiday: 5,
      absence: 6,
      substitute_holiday: 7,
      direct_return: 8,
      business_trip: 9,
    },
    employee_reserve: {},
    staff_reserve: {},
  }),
  computed: {
    isThisMonth() {
      if (this.employee.contract_type == 1) {
        return (
          this.filter.month == this.maxMonth &&
          this.filter.contract.contract_type &&
          this.filter.contract.contract_type.type == 1
        );
      } else {
        return (
          this.filter.month == this.maxMonth &&
          this.filter.contract.contract_type &&
          this.filter.contract.contract_type.type != 1
        );
      }
    },
    maxMonth() {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      let d = dt.getDate();
      if (
        this.filter.contract.contract_type &&
        this.filter.contract.contract_type.type == 1
      ) {
        m = d >= 21 ? m + 1 : m;
        if (m > 12) {
          m = 1;
          y++;
        }
        return y + "-" + ("00" + m).slice(-2);
      } else if (this.filter.contract.contract_type) {
        m = d <= 6 ? m - 1 : m;
        if (m == 0) {
          m = 12;
          y--;
        }
        return y + "-" + ("00" + m).slice(-2);
      } else {
        return "";
      }
    },
    minMonth() {
      let now = new Date();
      let dt = new Date(now.setMonth(now.getMonth() - 2));
      let y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      let d = dt.getDate();
      if (
        this.filter.contract.contract_type &&
        this.filter.contract.contract_type.type == 1
      ) {
        m = d >= 21 ? m + 1 : m;
        if (m > 12) {
          m = 1;
          y++;
        }
        return y + "-" + ("00" + m).slice(-2);
      } else {
        m = d <= 6 ? m - 1 : m;
        if (m == 0) {
          m = 12;
          y--;
        }
        return y + "-" + ("00" + m).slice(-2);
      }
    },
    closedMonth() {
      let now = new Date();
      let dt = new Date(now.setMonth(now.getMonth() - 1));
      let y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      let d = dt.getDate();
      if (
        this.filter.contract.contract_type &&
        this.filter.contract.contract_type.type == 1
      ) {
        m = d >= 21 ? m + 1 : m;
        if (m > 12) {
          m = 1;
          y++;
        }
        return y + ("00" + m).slice(-2);
      } else {
        m = d <= 6 ? m - 1 : m;
        if (m == 0) {
          m = 12;
          y--;
        }
        return y + ("00" + m).slice(-2);
      }
    },
    calcWorkDays() {
      if (
        this.filter.contract.contract_type &&
        this.filter.contract.contract_type.type == 1
      ) {
        const work_kind = [
          this.kind.public_holiday,
          this.kind.paid_holiday,
          this.kind.special_holiday,
          this.kind.absence,
          this.kind.substitute_holiday,
          this.kind.not_enrolled,
        ];
        const result = this.filteredTimecards.filter(
          (t) => !work_kind.includes(t.kind)
        );
        return result.length;
      } else {
        const work_kind = [
          this.kind.work_on_holiday,
          this.kind.work_on_weekdays,
          this.kind.business_trip,
          this.kind.direct_return,
        ];
        const result = this.filteredTimecards.filter((t) =>
          work_kind.includes(t.kind)
        );
        return result.length;
      }
    },
    calcPublicHoliday() {
      if (
        this.filter.contract.contract_type &&
        this.filter.contract.contract_type.type == 1
      ) {
        const holiday_kind = [
          this.kind.public_holiday,
          this.kind.substitute_holiday,
        ];
        const result = this.filteredTimecards.filter((t) =>
          holiday_kind.includes(t.kind)
        );
        return result.length;
      } else {
        const holiday_kind = [
          this.kind.public_holiday,
          this.kind.substitute_holiday,
        ];
        const result = this.filteredTimecards.filter(
          (t) => holiday_kind.includes(t.kind) | (t.kind === null)
        );
        return result.length;
      }
    },
    calcPaidHoliday() {
      const holiday_kind = [this.kind.paid_holiday];
      const result = this.filteredTimecards.filter((t) =>
        holiday_kind.includes(t.kind)
      );
      return result.length;
    },
    calcSpecialVacation() {
      const holiday_kind = [this.kind.special_holiday];
      const result = this.filteredTimecards.filter((t) =>
        holiday_kind.includes(t.kind)
      );
      return result.length;
    },
    calcAbsentDays() {
      const absent_kind = [this.kind.absence];
      const result = this.filteredTimecards.filter((t) =>
        absent_kind.includes(t.kind)
      );
      return result.length;
    },
    calcWorkHours() {
      const work_kind = [
        this.kind.work_on_weekdays,
        this.kind.work_on_holiday,
        this.kind.business_trip,
        this.kind.direct_return,
      ];
      let hours = 0;
      this.filteredTimecards.filter((t) => {
        if (work_kind.includes(t.kind)) {
          hours += t.work_minutes;
        }
      });
      return hours / 60;
    },
    calcOvertimeHours() {
      const work_kind = [this.kind.work_on_weekdays, this.kind.work_on_holiday];
      let hours = 0;
      this.filteredTimecards.filter((t) => {
        if (work_kind.includes(t.kind)) {
          hours += t.overtime_minutes;
        }
      });
      return hours / 60;
    },
    calcTardyHours() {
      const work_kind = [this.kind.work_on_weekdays, this.kind.work_on_holiday];
      let hours = 0;
      this.filteredTimecards.filter((t) => {
        if (work_kind.includes(t.kind)) {
          hours += t.tardy_and_early_leaving_minutes;
        }
      });
      return hours / 60;
    },
    calcMidnightHours() {
      const work_kind = [1, 2];
      let hours = 0;
      this.filteredTimecards.filter((t) => {
        if (work_kind.includes(t.kind)) {
          hours += t.midnight_minutes ? t.midnight_minutes : 0;
        }
      });
      return hours / 60;
    },
    employeeSelection() {
      let selection = [];
      this.employees.forEach((item) => {
        selection.push({
          id: item.identification,
          text: `${item.identification}:${item.name}`,
        });
      });
      return selection;
    },
    errorTimecards() {
      const work_kind = [1, 2, 8];
      if (this.summary.length <= 1) {
        if (this.filter.is_all == 0) {
          return this.filteredTimecards.filter((t) => {
            if (t.work_end_at) {
              return (
                work_kind.includes(t.kind) &&
                t.work_end_at.includes("00:00:00") &&
                t.total_minutes == 0
              );
            } else {
              return false;
            }
          });
        }
        return this.timecards;
      } else {
        if (this.filter.is_all == 0) {
          return this.filteredTimecards.filter((t) => {
            if (t.work_end_at) {
              return (
                work_kind.includes(t.kind) &&
                t.work_end_at.includes("00:00:00") &&
                t.total_minutes == 0
              );
            } else {
              return false;
            }
          });
        }
        return this.filteredTimecards;
      }
    },
    filteredTimecards() {
      if (this.summary.length <= 1) {
        return this.timecards;
      } else {
        return this.timecards.filter(
          (t) => t.summary === this.filter.contract.id
        );
      }
    },
    enableApproveButton() {
      if (this.summary.length < 1 || !this.filter.contract) {
        return false;
      }
      return (
        (this.filter.contract.status == 1 ||
          this.filter.contract.status == 3) &&
        this.user.access_right != 3
      );
    },
    enableConfirmButton() {
      if (this.summary.length < 1 || !this.filter.contract) {
        return false;
      }

      return (
        (this.filter.contract.status == 0 ||
          this.filter.contract.status == 2) &&
        (this.filter.employee == this.user.identification ||
          this.user.access_right == 1)
      );
    },
  },
  watch: {
    "filter.employee"() {
      this.selectedEmployeeYear();
    },
    "filter.month"() {
      if (this.filter.month !== "") {
        this.selectedEmployeeYear();
      }
    },
    "filter.contract"() {
      if (this.filter.contract !== "") {
        this.filter.month =
          this.filter.month !== ""
            ? this.filter.month
            : this.filter.contract.month.slice(0, 4) +
              "-" +
              this.filter.contract.month.slice(4, 6);
      }
    },
  },
  mounted() {
    if (this.$route.query.month) {
      this.filter.month = `${this.$route.query.month.slice(
        0,
        4
      )}-${this.$route.query.month.slice(4, 6)}`;
    }
    if (this.$route.query.employee) {
      this.filter.employee = `${this.$route.query.employee}`;
    }
    if (!this.filter.month || !this.filter.employee) {
      this.init();
    }

    if (this.$route.query.registered) {
      this.registeredMessage = `勤怠情報を更新しました。(${this.$route.query.registered})`;
    }
    if (this.$route.query.updated) {
      this.registeredMessage = this.$route.query.updated;
    }
    if (this.$route.query.errors) {
      this.errorMessage = `${this.$route.query.errors}`;
    }
  },
  methods: {
    init(employee, month) {
      this.$emit("loading", true);
      client
        .get("/api/timecard/timecard", { params: { employee, month } })
        .then((res) => {
          console.log(res.data);
          this.timecards = res.data.timecards;
          this.today = this.formatDate2(new Date());
          this.employees = res.data.employees;
          this.employee = res.data.employee;
          this.employee_reserve = res.data.employee_reserve;
          this.staff_reserve = res.data.staff_reserve;
          this.user = res.data.user;
          this.filter.employee =
            this.filter.employee != ""
              ? this.filter.employee
              : this.employee.identification;
          this.summary = res.data.summary;
          if (this.summary.length > 1) {
            this.filter.contract =
              this.summary[0].contract_type.type == this.employee.contract_type
                ? this.summary[0]
                : this.summary[1];
          } else if (this.summary.length === 1) {
            this.filter.contract = this.summary[0];
          }
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    selectedEmployeeYear() {
      this.init(this.filter.employee, this.filter.month);
    },
    formatDate2(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      return y + "-" + m + "-" + d;
    },
    formatSeconds(minutes) {
      let hour = Math.floor(minutes / 60);
      const minute = ("00" + (minutes % 60)).slice(-2);
      return hour + ":" + minute;
    },
    selectColor(kind, weekday) {
      if (kind == 0) {
        return "#adadad";
      }
      return weekday === 6 ? "#fedcdc" : weekday === 5 ? "#dcdcfe" : "white";
    },
    editTimecard(id, kind) {
      if (this.filter.contract.status == 9 && kind != 0) {
        this.$router.replace(`/timecard_detail/${id}`);
      } else if (kind != 0) {
        if (
          this.user.access_right == 1 &&
          kind == 7 &&
          this.user.identification == this.employee.identification
        ) {
          this.$router.replace(`/substitute_holiday/${id}`);
        } else if (this.user.access_right == 1) {
          this.$router.replace(`/attendance/${id}`);
        } else if (this.filter.month <= this.minMonth) {
          this.$router.replace(`/timecard_detail/${id}`);
        } else if (
          this.filter.contract.status == 0 ||
          this.filter.contract.status == 2
        ) {
          if (
            this.user.occupation == 2 &&
            (kind == null || kind == 3) &&
            this.user.identification == this.employee.identification
          ) {
            this.$router.replace(`/attendance/${id}`);
          } else if (
            kind == 7 &&
            this.user.identification == this.employee.identification
          ) {
            this.$router.replace(`/substitute_holiday/${id}`);
          } else {
            this.$router.replace(`/timecard_detail/${id}`);
          }
        } else {
          this.$router.replace(`/timecard_detail/${id}`);
        }
      }
    },
    exportTimecard: function (summary) {
      this.$emit("loading", true);
      client({
        url: "/api/timecard/export",
        method: "GET",
        responseType: "blob",
        params: { summary },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `出勤簿_${this.filter.employee}_${this.filter.month}.csv`
          );
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    showHistory(id) {
      if (this.user.is_superuser) {
        this.$emit("loading", true);
        client({
          url: `/api/timecard/timecard/history/${id}`,
        })
          .then((res) => {
            this.histories = res.data;
            this.$emit("loading", false);
          })
          .catch(() => {
            this.$emit("loading", false);
          });
        this.show_history_modal = true;
      }
    },
    confirmed() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_confirm);
      modal.hide();
      this.registeredMessage = "勤怠を確定しました。";
      this.init(this.filter.employee, this.filter.month);
    },
    approved(status) {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_approve);
      modal.hide();
      this.registeredMessage = `勤怠を${status}しました。`;
      this.init(this.filter.employee, this.filter.month);
    },
    closeConfirmModal(detail) {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_confirm);
      if (detail) {
        this.errorMessage = detail;
      }
      modal.hide();
    },
    closeApproveModal(detail) {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_approve);
      if (detail) {
        this.errorMessage = detail;
      }
      modal.hide();
    },
    toPrevMonth() {
      let year = Number(this.filter.month.slice(0, 4));
      let month = Number(this.filter.month.slice(-2));
      if (month === 1) {
        this.filter.month = `${year - 1}-12`;
      } else {
        month = ("00" + (month - 1)).slice(-2);
        this.filter.month = `${year}-${month}`;
      }
    },
    toNextMonth() {
      let year = Number(this.filter.month.slice(0, 4));
      let month = Number(this.filter.month.slice(-2));
      if (month === 12) {
        this.filter.month = `${year + 1}-01`;
      } else {
        month = ("00" + (month + 1)).slice(-2);
        this.filter.month = `${year}-${month}`;
      }
    },
    exportMyList() {
      this.$emit("loading", true);
      client
        .get("/api/system/timecard_list", {
          params: { summary: this.filter.contract.id },
        })
        .then((res) => {
          const element = res.data;
          const option = {
            filename: `出勤簿${this.filter.contract.month}.pdf`,
          };
          html2pdf().set(option).from(element).save();
          this.$emit("loading", false);
        })
        .catch(() => {
          this.$emit("loading", false);
        });
    },
    exportAllList() {
      this.$emit("loading", true);
      client
        .get("/api/system/timecard_list", {
          params: { month: this.filter.month },
        })
        .then((res) => {
          const element = res.data;
          const option = {
            filename: `出勤簿${this.filter.month}.pdf`,
          };
          html2pdf().set(option).from(element).save();
          this.$emit("loading", false);
        })
        .catch(() => {
          this.$emit("loading", false);
        });
    },
    exportAttendanceRecord(value) {
      window.open(
        `#/timecard_list_download?month=${this.filter.month}&type=${value}`
      );
    },
    exportErrorFormat() {
      this.$emit("loading", true);
      client
        .get("/api/system/error_list", {
          params: { summary: this.filter.contract.id },
        })
        .then((res) => {
          const element = res.data;
          const option = {
            filename: `エラー報告書_${this.filter.contract.month}.pdf`,
            jsPDF: { orientation: "landscape" },
          };
          html2pdf().set(option).from(element).save();
          this.$emit("loading", false);
        })
        .catch(() => {
          this.errorMessage = "出勤簿の作成に失敗しました。";
          this.$emit("loading", false);
        });
    },
    backToSummary() {
      this.$router.push("/timecard_summary?month=" + this.$route.query.month);
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
#summary {
  table-layout: fixed;
}
#summary th {
  text-align: center;
  border: solid 1px #333;
  background-color: #ccc;
}
#summary td {
  text-align: right;
  padding: 0.5rem;
  border: solid 1px #333;
}
.td-nowrap {
  white-space: nowrap;
}
.text-overflow {
  max-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen {
  .table-scroll {
    height: 450px;
    overflow: auto;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 2;
  }
  .print-area * {
    display: none;
  }
  .cursor-none {
    user-select: none;
  }
  .cursor-none:hover {
    cursor: initial;
  }
  .text-l {
    font-size: 18px;
    font-weight: bold;
  }
}
@media print {
  button *,
  br,
  div.no-print *,
  .no-print {
    display: none !important;
  }
  thead * {
    -webkit-print-color-adjust: exact;
  }
  .table-scroll {
    margin: 0;
  }
  #summary th {
    font-size: 12px;
  }
  table.table td {
    border: 1px solid #258 !important;
  }
  .cursor-none {
    -webkit-print-color-adjust: exact;
  }
}
@page {
  size: A4;
  margin: -10px;
  padding: 0;
}
.history_modal.modal {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.comment-area {
  white-space: pre-line;
  padding: 3px;
}
</style>
