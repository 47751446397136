<template>
  <form class="request-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/request_list')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>
    <h1 class="mb-3">
      承認&emsp;
      <span>新規登録</span>
    </h1>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <div class="row">
      <div class="col-sm-3">
        <label class="mb-0">申請者</label>
        <input
          type="text"
          disabled
          :value="employee.name"
          class="form-control"
        />
      </div>
      <div class="col-sm-3">
        <label class="mb-0">申請種別</label>
        <input
          type="text"
          disabled
          :value="request.kind_text"
          class="form-control"
        />
      </div>
      <div class="col-sm-3">
        <label class="mb-0" v-if="[4, 5].includes(request.kind)">
          取得(予定)日
        </label>
        <label class="mb-0" v-else-if="request.kind == 2">出勤日</label>
        <label class="mb-0" v-else-if="request.kind == 8">直行直帰日</label>
        <label class="mb-0" v-else-if="request.kind == 9">出張(予定)日</label>
        <input
          type="date"
          disabled
          :value="request.start_date"
          class="form-control"
        />
      </div>
      <div class="col-sm-3" v-if="[4, 5, 9].includes(request.kind)">
        <label class="mb-0" v-if="request.kind == 9">出張(予定)日</label>
        <label class="mb-0" v-else>取得(予定)日</label>
        <input
          type="number"
          disabled
          :value="request.days"
          class="form-control"
        />
        <p>有休残: {{ employee.day_count_of_paid_holiday }}日</p>
      </div>
      <div class="col-sm-3" v-if="request.kind == 2">
        <label class="mb-0">指定振替休日日</label>
        <input
          type="date"
          disabled
          :value="request.related_date"
          class="form-control"
        />
      </div>
      <div class="col-sm-6" v-if="[2, 8].includes(request.kind)">
        <label>実就業時間</label>
        <div class="input-group">
          <input
            type="time"
            class="form-control"
            :value="request.work_start_at"
            disabled
          />
          <div class="input-group-text">～</div>
          <input
            type="time"
            class="form-control"
            v-model="request.work_end_at"
            disabled
          />
        </div>
      </div>
      <div class="col-sm-6" v-if="[2, 8].includes(request.kind)">
        <label>休憩時間</label><br />
        <div class="btn-group">
          <div
            class="btn-group col-sm-4"
            role="group"
            aria-label="break_minutes"
            v-for="break_min in minutes"
            :key="break_min"
          >
            <input
              type="radio"
              class="btn-check"
              name="break"
              :id="`break_minutes-${break_min}`"
              :value="break_min"
              v-model="request.break_minutes"
              disabled
            />
            <label
              class="btn btn-outline-success py-2"
              :for="`break_minutes-${break_min}`"
            >
              {{ break_min }}分
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="request.kind == 5">
        <label>特別休暇種別</label><br />
        <div class="btn-group">
          <div
            class="btn-group"
            role="group"
            aria-label="spacial_kind"
            v-for="(value, key) in special"
            :key="key"
          >
            <input
              type="radio"
              class="btn-check"
              name="special_kind"
              :id="`special_kind-${key}`"
              :value="key"
              v-model="request.special_kind"
              disabled
            />
            <label
              class="btn btn-outline-success py-2 px-4"
              :for="`special_kind-${key}`"
            >
              {{ value }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="request.kind == 5">
        <label>続柄</label>
        <input
          type="text"
          class="form-control"
          v-model="request.relationship"
          disabled
        />
      </div>
      <div class="col-md-6" v-if="request.kind == 5">
        <label>特別休暇種別(その他)</label>
        <input
          type="text"
          class="form-control"
          v-model="request.special_reason"
          disabled
        />
      </div>
      <div class="col-sm-12">
        <label class="mb-0">申請理由</label>
        <textarea
          type="text"
          disabled
          :value="request.reason"
          class="form-control"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <label class="mb-0">ステータス</label>
      <div class="col-sm-12 btn-group">
        <div
          class="btn-group col-md-1"
          role="group"
          aria-label="kind"
          v-for="(value, key) in statusChoice"
          :key="key"
        >
          <input
            type="radio"
            class="btn-check"
            :class="{ 'is-invalid': !!errors.stats }"
            name="kind"
            :id="`kind-${key}`"
            :value="key"
            v-model="form.status"
          />
          <label class="btn btn-outline-success py-2" :for="`kind-${key}`">
            {{ value }}
          </label>
        </div>
      </div>
      <div v-if="errors.status" class="invalid-feedback d-block">
        {{ errors.status[0] }}
      </div>
      <div class="col-sm-12">
        <label>承認者コメント</label>
        <textarea
          cols="2"
          v-model="form.comment"
          :class="{ 'is-invalid': !!errors.comment }"
          :disabled="disabled_form"
        >
        </textarea>
        <div v-if="errors.comment" class="invalid-feedback d-block">
          {{ errors.comment[0] }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/request_list')"
        >
          キャンセル
        </button>
      </div>
      <button type="submit" class="btn btn-primary" v-if="!disabled_form">
        &emsp;登録&emsp;
      </button>
    </div>
    <transition name="fade">
      <div v-if="validated">
        <div class="modal" @click.self="validated = false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title">申請&emsp;確認</h6>
                <button
                  type="button"
                  class="btn-close"
                  @click="validated = false"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="list-group" v-if="requests.length > 0">
                      <li
                        v-for="(value, key) in requests"
                        :key="key"
                        class="list-group-item"
                      >
                        <p>
                          {{ value.start_date }}:{{ value.kind_text }}({{
                            value.status_text
                          }})
                        </p>
                      </li>
                    </ul>
                    <ul class="list-group" v-if="timecards.length > 0">
                      <li
                        v-for="(value, key) in timecards"
                        :key="key"
                        class="list-group-item"
                      >
                        <p>
                          {{ value.date }}:{{ value.kind_text }}({{
                            value.work_start_time
                          }}～{{ value.work_finish_time }})
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-12">
                    {{
                      request.start_date
                    }}に承認待ちの申請または出勤打刻があります。<br />承認する場合は未承認の申請を却下し申請内容を勤怠に反映しますが、よろしいですか。
                  </div>
                </div>
                <div class="d-flex justify-content-around">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    @click.self="validated = !validated"
                  >
                    キャンセル
                  </button>
                  <button type="submit" class="btn btn-primary">
                    &emsp;登録&emsp;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop show"></div>
      </div>
    </transition>
  </form>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "FormView",
  components: { BsAlert },
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: "",
    authorizer: "",
    request: "",
    disabled_form: false,
    validated: false,
    mode: "validation",
    requests: [],
    timecards: [],
    special: { 1: "忌引き", 2: "結婚休暇", 3: "その他" },
    statusChoice: { 1: "未承認", 2: "承認済", 0: "却下" },
    minutes: [0, 60, 75, 90, 120],
    form: {
      status: "",
      authorizer: "",
      comment: "",
    },
  }),
  mounted() {
    this.$emit("loading", true);
    client
      .get("/api/request/approve/" + this.$route.params.id)
      .then((res) => {
        this.authorizer = res.data.employee;
        this.request = res.data.request;
        this.employee = res.data.request.employee;
        this.form.authorizer = this.authorizer.identification;
        this.form.status = this.request.status;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
          this.errorMessage = error.response.data.detail;
          this.disabled_form = true;
        }
        this.$emit("loading", false);
        this.$router.push(`/request_list?error=${this.errorMessage}`);
      });
  },
  watch: {
    validated() {
      this.mode = this.validated ? "register" : "validation";
    },
  },
  methods: {
    register() {
      this.$emit("loading", true);
      client
        .put(
          `/api/request/approve/${this.$route.params.id}?mode=${this.mode}`,
          this.form
        )
        .then((res) => {
          this.$emit("loading", false);
          if (this.mode === "validation") {
            client
              .get(
                `/api/request/request/check?start_date=${
                  this.request.start_date
                }&employee=${this.$route.params.id}&days=${
                  this.request.days
                }&kind=${this.request.kind}&related_date=${
                  this.request.related_date || ""
                }`
              )
              .then((res) => {
                this.requests = res.data.requests;
                this.timecards = res.data.timecards;
                if (this.requests.length > 0 || this.timecards.length > 0) {
                  this.validated = true;
                } else {
                  this.mode = "register";
                  this.register();
                }
              });
          } else {
            this.$router.push(
              `/request_list?approved=${res.data.kind_text}&status=${res.data.status_text}`
            );
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
.modal {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
