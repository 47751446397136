<template>
  <form class="export-form" @submit.prevent="register">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
      type="button"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span
      >キャンセル
    </button>

    <h1 class="mb-3">勤務実績&ensp;出力</h1>
    <bs-alert
      v-if="errorMessage"
      :message="errorMessage"
      color="danger"
      closable
      @close="errorMessage = ''"
    />
    <h2>出力年月</h2>
    <div class="row">
      <div class="col-md-3">
        <label>開始月</label>
        <input type="month" class="form-control" v-model="form.start" />
      </div>
      <div class="col-md-3">
        <label>終了月</label>
        <input type="month" class="form-control" v-model="form.end" />
      </div>
    </div>

    <h2>出力対象</h2>
    <label>契約形態</label>
    <div class="row">
      <div class="btn-group col-md-12">
        <div
          class="col-md-3 btn-group"
          role="group"
          v-for="contract in contract_types"
          :key="contract.value"
        >
          <input
            type="checkbox"
            class="btn-check"
            v-model="form.contract"
            :value="contract.value"
            :id="`contract${contract.value}`"
          />
          <label
            class="btn btn-outline-success py-2"
            :for="`contract${contract.value}`"
          >
            {{ contract.name }}
          </label>
        </div>
      </div>
    </div>

    <label>職種</label>
    <div class="row">
      <div class="btn-group col-md-12">
        <div
          class="col-md-2 btn-group"
          role="group"
          v-for="occupation in occupation_types"
          :key="occupation.value"
        >
          <input
            type="checkbox"
            class="btn-check"
            v-model="form.occupation"
            :value="occupation.value"
            :id="`occupation${occupation.value}`"
          />
          <label
            class="btn btn-outline-success py-2"
            :for="`occupation${occupation.value}`"
          >
            {{ occupation.name }}
          </label>
        </div>
      </div>
    </div>

    <div v-if="target_month" class="mx-3">
      <span class="text-muted">{{ target_month }}</span>
    </div>
    <div class="col-sm-6 m-3">
      <button
        class="btn btn-export"
        @click="exportTimecard"
        :disabled="disableButton"
      >
        勤怠データ出力
      </button>
    </div>
  </form>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";

export default {
  name: "DownloadView",
  components: { BsAlert },
  data: () => ({
    errors: {},
    errorMessage: "",
    employee: {},
    timecard: {},
    now: "",
    target_month: "",
    form: {
      start: "",
      end: "",
      contract: [1, 2, 3],
      occupation: [1, 2],
    },
    contract_types: [],
    occupation_types: [],
  }),
  mounted() {
    this.$emit("loading", true);
    client
      .get("/api/system/export_choices")
      .then((res) => {
        this.contract_types = res.data.contract;
        this.occupation_types = res.data.occupation;
        this.$emit("loading", false);
      })
      .catch((error) => {
        console.error(error);
        this.$emit("loading", false);
      });
  },
  computed: {
    disableButton() {
      return !(
        this.form.start &&
        this.form.end &&
        this.form.occupation.length &&
        this.form.contract.length
      );
    },
  },
  methods: {
    exportTimecard: function () {
      this.$emit("loading", true);
      client({
        url: "/api/system/timecard_detail",
        method: "POST",
        responseType: "blob",
        data: this.form,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "日別実績.csv");
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.row > * {
  margin-bottom: 1rem;
}
.btn {
  &.btn-export {
    line-height: 1;
    width: 250px;
    height: 80px;
    font-size: 22px;
    color: white;

    &:disabled {
      background-color: #aaa;
    }
  }

  &.btn-export {
    background-color: #56b9cc;
  }

  span {
    color: white;
  }
}
</style>
