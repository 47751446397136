import axios from "axios";

const state = {
  token: "",
  user: {},
};

const mutations = {
  setAll(state, value) {
    state.token = value.token;
    state.user = value.user;
  },
  updateToken(state, value) {
    state.token = value;
  },
  updateUser(state, value) {
    state.user = { ...value };
  },
};

const getters = {
  isLoggedIn: (state) => {
    return !!state.token;
  },
  token: (state) => {
    return state.token;
  },
  user: (state) => {
    return state.user;
  },
};

const actions = {
  loggedIn({ commit }, value) {
    commit("setAll", value);
  },
  refreshToken({ commit }, value) {
    axios.defaults.headers.common.Authorization = "JWT " + state.token;
    commit("updateToken", value);
  },
  updateUser({ commit }, value) {
    commit("updateUser", value);
  },
  logout({ commit }) {
    commit("setAll", { token: "", user: {} });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
