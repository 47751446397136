<template>
  <div class="choice-list">
    <button
      class="btn btn-secondary mb-3 d-flex align-items-center"
      @click="$router.push('/')"
    >
      <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
    </button>
    <h1>マスター管理</h1>

    <div class="content">
      <bs-alert
        v-if="registeredMessage"
        :message="registeredMessage"
        color="success"
        closable
        @close="registeredMessage = ''"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        @close="errorMessage = ''"
      />

      <div class="my-3 d-flex">
        <div class="form-check form-check-inline my-auto">
          <input type="checkbox" id="is_show" v-model="filter.is_show" />
          <label for="is_show">全件表示</label>
        </div>
        <select
          v-model="filter.kind"
          class="form-select mx-2"
          style="width: 300px"
        >
          <option value="">全種別</option>
          <option v-for="(value, key) in kinds" :key="key" :value="key">
            {{ value }}
          </option>
        </select>

        <div class="ms-auto d-flex">
          <button
            type="button"
            class="btn btn-primary d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_form"
            @click="editId = 0"
          >
            <span class="material-icons text-white me-1">add</span>
            新規登録
          </button>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>種別</th>
            <th>選択値</th>
            <th>項目名</th>
            <th>表示</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody v-if="choices.length > 0">
          <tr v-for="choice in filteredChoices" :key="choice.id">
            <td>{{ choice.kind_label }}</td>
            <td>{{ choice.value }}</td>
            <td>{{ choice.text }}</td>
            <td class="text-center">
              <span v-if="choice.is_valid" class="material-icons me-1"
                >radio_button_unchecked</span
              >
            </td>
            <td class="text-center">
              <button
                class="btn btn-sm btn-primary mx-3"
                data-bs-toggle="modal"
                data-bs-target="#modal_form"
                @click="editId = choice.id"
                :disabled="choice.value == 9999"
              >
                編集
              </button>
              <button
                class="btn btn-sm btn-danger mx-3"
                @click="toggleValid(choice.id, choice.is_valid)"
                :disabled="choice.value == 9999"
              >
                {{ choice.is_valid ? "非表示" : "表示" }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">登録がありません。</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="modal fade"
      id="modal_form"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_form"
    >
      <div class="modal-dialog modal-dialog-centered">
        <choice-form-view :id="editId" @registered="registered" />
      </div>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import choiceFormView from "./FormView";
import BsAlert from "@/components/Alert.vue";
import * as bootstrap from "bootstrap";
import * as constants from "@/constants";

export default {
  name: "ChoiceListView",
  components: { BsAlert, choiceFormView },
  data: () => ({
    choices: [],
    editId: -1,
    registeredMessage: "",
    errorMessage: "",
    kinds: [],
    filter: {
      is_show: true,
      kind: "",
    },
  }),
  mounted() {
    this.init();
  },
  computed: {
    filteredChoices() {
      return this.choices.filter((e) => {
        if (this.filter.kind) {
          return this.filter.is_show
            ? this.filter.kind == e.kind
            : this.filter.kind == e.kind && e.is_valid;
        }
        return this.filter.is_show ? e : e.is_valid;
      });
    },
  },
  methods: {
    init() {
      this.$emit("loading", true);
      let url = "/api/system/choice";
      client
        .get(url)
        .then((response) => {
          this.choices = response.data.choices;
          this.kinds = response.data.kinds;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    registered() {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_form);
      modal.hide();
      this.registeredMessage = "早退・遅刻理由を登録しました。";
      this.editId = -1;
      this.init();
    },
    toggleValid(id, valid) {
      if (
        !confirm(`本当に${valid ? "非表示" : "表示"}にしてよろしいですか？`)
      ) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete(`/api/system/choice/${id}`)
        .then(() => {
          this.registeredMessage = `${valid ? "非表示" : "表示"}にしました。`;
          this.$emit("loading", false);
          this.init();
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
.content {
  width: 800px;
  margin: auto;
}
button.btn-danger {
  width: 60px;
}
</style>
