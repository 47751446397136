<template>
  <div class="alert fade show py-2" :class="classes" role="alert">
    <span v-html="message"></span>
    <button
      v-if="closable"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
export default {
  name: "BsAlert",
  props: {
    showing: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "light",
    },
    closable: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      let classes = [`alert-${this.color}`];
      if (this.closable) {
        classes.push("alert-dismissible");
      }

      return classes;
    },
  },
};
</script>

<style scoped>
span {
  color: inherit;
  font-size: inherit;
}
</style>
