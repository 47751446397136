import axios from "axios";
import * as constants from "../constants";
import vue from "../main";
import store from "../store";

const client = axios.create({
  baseURL: constants.API_URL,
});

const gotoLogin = () => {
  vue.$router.push({ path: "/login", query: { redirect: vue.$route.path } });
};

client.interceptors.request.use((request) => {
  request.headers["Authorization"] = store.getters["user/token"]
    ? "JWT " + store.getters["user/token"]
    : "";
  request.headers["OGGI-CLIENT-HOSTNAME"] = store.getters["client/hostname"];
  return request;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // エラー時の処理
    if (error.response?.status === constants.HTTP_RESPONSE_UNAUTHORIZED) {
      store.dispatch("user/logout");
      gotoLogin();
    } else if (error.response?.status === constants.HTTP_RESPONSE_FORBIDDEN) {
      vue.$router.push({
        name: "error",
        query: { status: constants.HTTP_RESPONSE_FORBIDDEN },
      });
    } else if (error.response?.status >= 500) {
      vue.$router.push({ path: "/error" });
    }

    return Promise.reject(error);
  }
);

export default client;
