<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    aria-labelledby="form_title"
    aria-hidden="true"
    ref="modal"
  >
    <div :class="['modal-dialog', 'modal-dialog-centered', modalSize]">
      <div class="modal-content">
        <div class="modal-header py-2">
          <slot name="title">
            <h6 class="modal-title">{{ title }}</h6>
            <button
              v-if="closable"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="modal_close"
            ></button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "BsModal",
  props: {
    modalId: { type: String, required: true },
    title: String,
    closable: { type: Boolean, default: true },
    modalSize: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bsModal: null,
    };
  },
  mounted() {
    this.bsModal = new Modal(this.$refs.modal, {
      keyboard: this.closable,
      backdrop: this.closable ? true : "static",
    });
  },
  methods: {
    show() {
      this.bsModal.show();
    },
  },
};
</script>

<style scoped></style>
