<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">休日設定&emsp;一括登録</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        ref="modal_close"
      ></button>
    </div>
    <div class="modal-body">
      <bs-alert
        message="登録内容を削除し、ファイルの内容で登録しなおします。注意してください。"
        color="warning"
      />

      <bs-alert
        v-if="errorMessage"
        :message="errorMessage"
        color="danger"
        closable
        style="font-size: 14px"
      />

      <form class="holiday-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>

        <div class="row">
          <div class="col-6 mb-3">
            <div class="input-group">
              <div class="input-group-text">対象年</div>
              <select
                class="form-select"
                v-model="form.year"
                :class="{ 'is-invalid': !!errors.year }"
              >
                <option v-for="year in yearSelection" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
              <div v-if="errors.year" class="invalid-feedback">
                {{ errors.year[0] }}
              </div>
            </div>
          </div>
          <div class="col-6 mb-3"></div>
          <div class="col-8 mb-3">
            <input
              class="form-control"
              type="file"
              @change="form.file = $event.target.files[0]"
            />
          </div>
          <div class="col-12 mb-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                name="has_header"
                id="has_header"
                value="1"
                v-model="form.has_header"
              />
              <label class="form-check-label" for="has_header"
                >1行目にタイトルがある</label
              >
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button class="btn btn-primary">登録</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
import BsAlert from "@/components/Alert.vue";

export default {
  name: "HolidayImportView",
  components: { BsAlert },
  data: () => ({
    errors: {},
    form: { year: "", file: null, has_header: false },
    yearSelection: [],
    errorMessage: "",
  }),
  mounted() {
    client
      .get("/api/system/holiday/0")
      .then((res) => {
        this.yearSelection = res.data.year_selections;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    register() {
      const url = "/api/system/holiday/import";
      const data = new FormData();
      data.append("year", this.form.year);
      data.append("file", this.form.file);
      data.append("has_header", this.form.has_header ? true : "");
      const headers = { "content-type": "multipart/form-data" };

      client
        .post(url, data, { headers })
        .then(() => {
          this.$emit("registered", this.year);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errorMessage = error.response.data.error.replace(
              /\n/g,
              "<br>"
            );
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
