<template>
  <div class="employee-list">
    <div class="no-print">
      <button
        class="btn btn-secondary mb-3 d-flex align-items-center"
        @click="$router.push('/')"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <h1 class="mb-3">勤怠管理集計&emsp;一覧</h1>
    </div>
    <div class="content">
      <div class="no-print">
        <bs-alert
          :message="unconfirmError"
          v-if="unconfirmError"
          color="danger"
          closable
          @close="unconfirmError = ''"
        />

        <bs-alert
          v-if="registeredMessage"
          :message="registeredMessage"
          color="success"
          closable
          @close="registeredMessage = ''"
        />

        <bs-alert
          v-if="errorMessage"
          :message="errorMessage"
          color="danger"
          closable
          @close="errorMessage = ''"
        />

        <bs-alert
          v-if="reserveMessage"
          :message="reserveMessage"
          color="secondary"
          closable
          @close="reserveList = []"
        />

        <bs-alert
          v-if="
            confirmReserveList &&
            confirmReserveList[0] &&
            confirmReserveList[0]['created_at'] &&
            confirmReserveList[0]['status'] == 0
          "
          message="確定予約処理が完了しました。確定処理が完了するのをお待ちください。"
          color="secondary"
          closable
          @close="confirmReserveList = []"
        />

        <bs-alert
          v-else-if="
            confirmReserveList &&
            confirmReserveList[0] &&
            confirmReserveList[0]['created_at'] &&
            confirmReserveList[0]['status'] == 2
          "
          message="勤怠確定が完了しました。"
          color="info"
          closable
          @close="confirmReserveList = []"
        />

        <ul
          class="list-group"
          v-else-if="
            confirmReserveList &&
            confirmReserveList[0] &&
            confirmReserveList[0]['created_at']
          "
        >
          <li class="list-group-item list-group-item-danger">
            <span class="list-group-item-danger"
              >勤怠一括確定に失敗しました。エラー内容を確認してください。</span
            >
            <span
              class="material-icons float-end list-group-item-danger fs-2"
              @click.self="showDetailMessage = !showDetailMessage"
              style="width: auto; height: auto"
              >{{
                showDetailMessage ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</span
            >
          </li>
          <li
            class="list-group-item"
            v-if="
              showDetailMessage &&
              confirmReserveList[0]['message'] &&
              confirmReserveList[0]['message']['errors']
            "
          >
            <div
              v-for="(error, i) in confirmReserveList[0]['message']['errors']"
              :key="i"
            >
              <hr v-if="i !== 0" />
              <span v-if="error.employee">{{ error.employee }}&emsp;・ </span>
              <span>
                {{ error.message }}
              </span>
            </div>
          </li>
        </ul>

        <div class="d-flex flex-sm-wrap">
          <div class="form-check form-check-inline fs-5 my-2">
            <input
              class="form-check-input"
              type="radio"
              name="target"
              id="employee"
              value="1"
              v-model="filter.contract"
            />
            <label class="form-check-label" for="employee"
              >社員・契約社員</label
            >
          </div>
          <div class="form-check form-check-inline fs-5 my-2">
            <input
              class="form-check-input"
              type="radio"
              name="target"
              id="stuff"
              value="2"
              v-model="filter.contract"
            />
            <label class="form-check-label" for="stuff"
              >パート・アルバイト</label
            >
          </div>
          <div class="m-2 d-flex">
            <input
              type="month"
              class="form-control"
              style="font-size: larger"
              v-model="filter.month"
              :max="targetMonth"
            />
          </div>
          <div class="m-2 d-flex">
            <select v-model="filter.status" class="form-select">
              <option value="">全件</option>
              <option
                v-for="(value, key) in statusList"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div class="m-2 d-flex">
            <select v-model="filter.history" class="form-select">
              <option value="">全件</option>
              <option value="1">勤務実績なし</option>
              <option value="2">勤務実績あり</option>
            </select>
          </div>
          <div class="m-2 d-flex">
            <select v-model="filter.absence_day_count" class="form-select">
              <option value="">全件</option>
              <option value="1">欠勤あり</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-sm-wrap justify-content-end">
          <div class="ms-auto d-flex" v-if="user.access_right === 1">
            <button
              class="btn btn-primary me-2 mb-2 d-flex align-items-center"
              @click="recountTimecard"
              v-if="summaryList.length > 0"
            >
              <span class="material-icons text-white me-1">star</span>
              再集計
            </button>
          </div>
          <div class="d-flex" v-if="user.is_superuser">
            <button
              class="btn btn-primary me-2 mb-2 d-flex align-items-center"
              @click="updatePaidHoliday"
              v-if="filter.contract == '2' && summaryList.length > 0"
            >
              有休基準時間登録
            </button>
          </div>
          <div class="d-flex" v-if="user.is_superuser">
            <button
              class="btn btn-primary me-2 mb-2 d-flex align-items-center"
              @click="clickButton"
              v-if="
                summaryList &&
                summaryList.length > 0 &&
                summaryList[0].status < 4
              "
              :disabled="disabledConfirm"
            >
              一括確定
            </button>
          </div>
          <div
            class="btn-group me-2 mb-2 d-flex align-items-center"
            v-if="user.access_right === 1"
          >
            <button
              type="button"
              class="btn btn-primary dropdown-toggle d-flex align-items-center"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            >
              出勤簿
            </button>
            <ul class="dropdown-menu dropdown-menu-lg-end">
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="exportAttendanceRecord()"
                >
                  ダウンロード
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="createReserve()"
                >
                  再作成予約登録
                </button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            class="btn btn-primary me-2 mb-2 d-flex align-items-center"
            @click="exportTimecard(filter)"
            :disabled="!this.filter.month"
          >
            <span class="material-icons text-white me-1">download</span>
            CSV出力
          </button>
          <button
            type="button"
            v-if="user.access_right === 1"
            class="btn btn-primary me-2 mb-2 d-flex align-items-center"
            @click="
              $router.push(
                `/tardy_early_leaving?month=${String(
                  filter.month
                )}&contract=${String(filter.contract)}`
              )
            "
            :disabled="!this.filter.month"
          >
            遅刻早退確認
          </button>
          <button
            type="button"
            v-if="user.access_right === 1"
            class="btn btn-primary me-2 mb-2 d-flex align-items-center"
            @click="
              $router.push(`/off_count_list?month=${String(filter.month)}`)
            "
            :disabled="!this.filter.month"
          >
            休日連続日数確認
          </button>
        </div>
      </div>
    </div>
    <div class="content overflow-scroll p-0">
      <div class="table-scroll">
        <table class="table table-hover sticky_table">
          <thead>
            <tr>
              <th>従業員</th>
              <th>出勤日数</th>
              <th>公休日数</th>
              <th>有給休暇<br />日数</th>
              <th>特別休暇<br />日数</th>
              <th>欠勤日数</th>
              <th>通常勤務<br />時間計</th>
              <th>普通残業<br />時間計</th>
              <th>深夜残業<br />時間計</th>
              <th>遅刻早退<br />時間計</th>
              <th>集計状況</th>
              <th>エラー<br />報告書<br />提出</th>
              <th v-if="user.is_superuser">管理者修正</th>
              <th
                v-if="filter.contract == 2 && user.is_superuser"
                style="max-width: 150px"
              >
                有休<br />基準時間
              </th>
              <th v-if="filter.contract == 2 && user.is_superuser">
                勤務時間+<br />有休時間
              </th>
            </tr>
          </thead>
          <tbody v-if="summaryList.length > 0">
            <tr
              v-for="summary in summaryList"
              :key="summary.id"
              @click="
                $router.push(
                  `/timecard?month=${String(filter.month).replace(
                    '-',
                    ''
                  )}&employee=${summary.employee.identification}&from=summary`
                )
              "
            >
              <td class="align-items-center">
                <span>
                  {{ summary.employee.name }}&emsp;({{
                    summary.employee.identification
                  }})
                </span>
                <span
                  v-if="summary && summary.contract_type.type == 2"
                  class="material-icons text-black-50 float-end"
                  >schedule</span
                >
                <span
                  v-if="summary && summary.contract_type.type == 3"
                  class="material-icons text-black-50 float-end"
                  >calendar_month</span
                >
              </td>
              <td class="text-right">{{ summary.working_day_count }}</td>
              <td class="text-right">{{ summary.public_holiday_count }}</td>
              <td class="text-right">{{ summary.paid_holiday_count }}</td>
              <td class="text-right">{{ summary.special_vacation_count }}</td>
              <td class="text-right">{{ summary.absence_day_count }}</td>
              <td class="text-right">{{ summary.normal_work_minutes / 60 }}</td>
              <td class="text-right">
                {{ summary.normal_overtime_minutes / 60 }}
              </td>
              <td class="text-right">
                {{ summary.midnight_overtime_minutes / 60 }}
              </td>
              <td class="text-right">
                {{ summary.tardy_and_early_leaving_minutes / 60 }}
              </td>
              <td>{{ summary.status_text }}</td>
              <td class="text-center">
                <span v-if="summary.has_difference" class="material-icons me-1"
                  >radio_button_unchecked</span
                >
              </td>
              <td
                @click.stop="checkManager(summary.id, summary.status)"
                v-if="user.is_superuser"
              >
                <div class="form-check fs-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="target"
                    :id="`modified_by_manager-${summary.id}`"
                    :checked="summary.modified_by_manager"
                    :disabled="summary.status >= 4"
                  />
                </div>
              </td>
              <td
                v-if="filter.contract == 2 && user.is_superuser"
                @click.stop
                style="max-width: 150px"
              >
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  step="0.5"
                  v-model="summary.hour_of_paid_holiday"
                  v-if="
                    summary.paid_holiday_count > 0 &&
                    summary.contract_type.type == 2
                  "
                  :disabled="summary.status >= 4"
                />
              </td>
              <td
                v-if="filter.contract == 2 && user.is_superuser"
                class="text-right"
              >
                <span v-if="summary.contract_type.type == 2">
                  {{ summary.work_and_paid_holiday_minutes / 60 }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="15">勤怠情報がありません。</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="modal modal-lg fade"
      id="modal_confirm"
      tabindex="-1"
      aria-labelledby="form_title"
      aria-hidden="true"
      ref="modal_confirm"
    >
      <div class="modal-dialog modal-dialog-centered">
        <confirm-form
          :messages="messages"
          :no-working-summary="noWorkingSummary"
          @approved="confirmed"
          @close="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BsAlert from "@/components/Alert.vue";
import client from "@/plugins/axios";
import ConfirmForm from "@/views/TimecardSummary/ConfirmForm";
import * as bootstrap from "bootstrap";
import * as constants from "@/constants";

export default {
  name: "TimecardSummaryList",
  components: { BsAlert, ConfirmForm },
  data: () => ({
    employees: "",
    user: "",
    departments: "",
    registeredMessage: "",
    errorMessage: "",
    today: "",
    summaryList: [],
    statusList: [],
    messages: [],
    filter: {
      month: "",
      contract: 1,
      status: "",
      history: "",
      absence_day_count: "",
    },
    unconfirmError: "",
    noWorkingSummary: [],
    reserveList: [],
    confirmReserveList: [],
    showDetailMessage: true,
  }),
  computed: {
    targetMonth() {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      let d = dt.getDate();

      if (this.filter.contract == 1) {
        m = d >= 21 ? m + 1 : m;
      } else {
        m = d <= 6 ? m - 1 : m;
      }
      if (m > 12) {
        m = 1;
        y++;
      }
      return y + "-" + ("00" + m).slice(-2);
    },
    reserveMessage() {
      if (
        this.reserveList &&
        this.reserveList[0] &&
        this.reserveList[0]["created_at"]
      ) {
        if (this.reserveList[0]["status"] == 0) {
          return `${this.reserveList[0]["created_at"]}：出勤簿作成予約が完了しました。作成処理が終わるまでお待ち下さい。`;
        } else if (this.reserveList[0]["status"] == 1) {
          return `${this.reserveList[0]["created_at"]}：出勤簿作成処理に失敗しました。再度予約登録をしてください。`;
        } else if (this.reserveList[0]["status"] == 2) {
          return `${this.reserveList[0]["created_at"]}：出勤簿作成が完了しました。ダウンロードボタンからダウンロードしていただけます。`;
        }
      }
      return "";
    },
    disabledConfirm() {
      if (
        this.confirmReserveList &&
        this.confirmReserveList[0] &&
        this.confirmReserveList[0]["created_at"] &&
        this.confirmReserveList[0]["status"] == 2
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "filter.contract"() {
      this.$store.dispatch("search/setSummary", this.filter);
      this.init();
    },
    "filter.status"() {
      this.$store.dispatch("search/setSummary", this.filter);
      this.init();
    },
    "filter.history"() {
      this.$store.dispatch("search/setSummary", this.filter);
      this.init();
    },
    "filter.absence_day_count"() {
      this.$store.dispatch("search/setSummary", this.filter);
      this.init();
    },
    "filter.month"(value) {
      if (value) {
        this.$store.dispatch("search/setSummary", this.filter);
        this.init();
      } else {
        this.summaryList = [];
      }
    },
  },
  mounted() {
    if (
      this.$store.getters["search/summary"] &&
      Object.keys(this.$store.getters["search/summary"]).length
    ) {
      this.filter = { ...this.$store.getters["search/summary"] };
      this.init();
    } else {
      client
        .get(`/api/timecard/timecard_summary`)
        .then((res) => {
          if (res.data.contract_type && res.data.contract_type.type != 1) {
            this.filter.contract = "2";
          }
          if (res.data.month && this.user.access_right === 1) {
            this.filter.month = `${res.data.month.slice(
              0,
              4
            )}-${res.data.month.slice(4, 6)}`;
            this.unconfirmError =
              this.filter.month < this.targetMonth
                ? `${this.filter.month.slice(0, 4)}年${this.filter.month.slice(
                    5,
                    7
                  )}月の管理者確定が完了していません。確定してください。`
                : "";
          } else {
            this.filter.month = this.targetMonth;
          }
          this.get_timecard_reserve();
          this.get_confirm_reserve();
        })
        .catch((error) => {
          console.error(error);
          this.get_timecard_reserve();
          this.get_confirm_reserve();
        });
    }
    this.user = this.$store.getters["user/user"];
  },
  methods: {
    init() {
      this.$emit("loading", true);
      let params = { ...this.filter };
      client
        .get("/api/timecard/timecard_summary", { params: params })
        .then((res) => {
          this.summaryList = res.data.summary;
          this.statusList = res.data.status_choices;
          this.get_timecard_reserve();
          this.get_confirm_reserve();
        })
        .catch((error) => {
          console.error(error);
          this.get_timecard_reserve();
          this.get_confirm_reserve();
        });
    },
    recountTimecard() {
      this.$emit("loading", true);
      client
        .get(`/api/timecard/timecard_recount?month=${this.filter.month}`)
        .then(() => {
          this.$emit("loading", false);
          this.init();
          this.registeredMessage = "勤怠の再集計が完了しました。";
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    checkManager(id, status) {
      if (status >= 4) {
        return;
      }
      this.$emit("loading", true);
      client
        .delete(`/api/timecard/timecard_checked/${id}`)
        .then(() => {
          this.$emit("loading", false);
          this.init();
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    updatePaidHoliday() {
      this.$emit("loading", true);
      let hour_list = new Array();
      for (let i = this.summaryList.length; i > 0; i--) {
        if (typeof this.summaryList[i - 1].hour_of_paid_holiday == "number") {
          hour_list.push({
            id: this.summaryList[i - 1].id,
            hour_of_paid_holiday: this.summaryList[i - 1].hour_of_paid_holiday,
          });
        }
      }

      client
        .put("/api/timecard/paid_holiday/0", { data: hour_list })
        .then(() => {
          this.$emit("loading", false);
          this.registeredMessage = "有休基準時間を更新しました。";
          this.init();
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    closeModal(detail) {
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_confirm);
      if (detail) {
        this.registeredMessage = "";
        this.errorMessage = detail;
      }
      modal.hide();
    },
    clickButton() {
      this.$emit("loading", true);

      client
        .get(
          `/api/timecard/manager/confirm?month=${this.filter.month}&contract=${this.filter.contract}`
        )
        .then((res) => {
          this.messages = res.data.messages;
          this.noWorkingSummary = res.data.no_working_list;
          if (
            (this.messages && this.messages.length > 0) ||
            (this.noWorkingSummary && this.noWorkingSummary.length > 0)
          ) {
            this.$emit("loading", false);
            var myModal = new bootstrap.Modal(
              document.getElementById("modal_confirm"),
              {
                keyboard: false,
              }
            );
            var modalToggle = document.getElementById("modal_confirm");
            myModal.show(modalToggle);
          } else {
            client
              .post("/api/timecard/manager/confirm", {
                month: this.filter.month,
                contract: this.filter.contract,
              })
              .then(() => {
                this.$emit("loading", false);
                this.errorMessage = "";
                this.registeredMessage = "勤怠確定の予約が完了しました。";
                this.init();
              })
              .catch((error) => {
                console.error(error);
                if (
                  error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST
                ) {
                  this.errors = { ...error.response.data };
                  if (this.errors.detail) {
                    this.$emit("close", this.errors.detail);
                    this.errorMessage = this.errors.detail;
                  }
                }
                this.$emit("loading", false);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
    exportTimecard: function (filter) {
      this.$emit("loading", true);
      client({
        url: "/api/timecard/timecard_summary/export",
        method: "GET",
        responseType: "blob",
        params: filter,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `出勤簿_${this.filter.contract}_${this.filter.month}.csv`
          );
          document.body.appendChild(link);
          link.click();
          this.$emit("loading", false);
        })
        .catch((error) => {
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.registeredMessage = "";
            this.errorMessage = error.response.data.detail;
          }
          this.$emit("loading", false);
        });
    },
    confirmed() {
      this.$emit("loading", true);
      const modal = bootstrap.Modal.getInstance(this.$refs.modal_confirm);
      modal.hide();

      client
        .post("/api/timecard/manager/confirm", {
          month: this.filter.month,
          contract: this.filter.contract,
        })
        .then(() => {
          this.$emit("loading", false);
          this.errorMessage = "";
          this.registeredMessage = "勤怠確定の予約が完了しました。";
          this.init();
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            if (this.errors.detail) {
              this.$emit("close", this.errors.detail);
              this.registeredMessage = "";
              this.errorMessage = this.errors.detail;
            }
          }
          this.$emit("loading", false);
        });
    },
    get_timecard_reserve() {
      let params = { ...this.filter };
      client
        .get("/api/timecard/reserve", { params: params })
        .then((res) => {
          this.reserveList = res.data;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.reserveList = [];
          this.$emit("loading", false);
        });
    },
    get_confirm_reserve() {
      let params = { ...this.filter };
      client
        .get("/api/timecard/confirm_reserve", { params: params })
        .then((res) => {
          console.log(res.data);
          this.confirmReserveList = res.data;
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.confirmReserveList = [];
          this.$emit("loading", false);
        });
    },
    exportAttendanceRecord() {
      window.open(
        `#/timecard_list_download?month=${this.filter.month}&type=${this.filter.contract}`
      );
    },
    createReserve() {
      this.$emit("loading", true);
      let data = {
        month: this.filter.month.replaceAll("-", ""),
        contract_type: this.filter.contract,
      };
      client
        .post("/api/timecard/reserve", data)
        .then(() => {
          this.init();
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            if (this.errors.messages) {
              this.registeredMessage = "";
              this.errorMessage = this.errors.messages;
            }
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
table.table td {
  border: 1px solid #258 !important;
}
@media print {
  button *,
  br,
  div.no-print *,
  .no-print {
    display: none !important;
  }
  thead * {
    -webkit-print-color-adjust: exact;
  }
  .table-scroll {
    margin: 0;
  }
}
@media screen {
  table.sticky_table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
  }

  .table-scroll {
    max-height: 80vh;
    min-width: 100%;
    width: max-content;
  }
  .sticky_table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .sticky_table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
  }
  .sticky_table thead th:first-child {
    z-index: 20;
  }
  .sticky_table td:first-child {
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
    left: 0;
    background-color: white;
  }
  .sticky_table td {
    z-index: 0;
  }
  .print-area * {
    display: none;
  }
}
@page {
  size: A4;
  margin: -10px;
  padding: 0;
}
.text-right {
  text-align: right;
}
</style>
