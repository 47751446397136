<template>
  <div>
    <form class="timecard-form" @submit.prevent="register">
      <button
        class="btn btn-secondary mb-3 d-flex align-items-center"
        @click="$router.replace(`/timecard`)"
        type="button"
      >
        <span class="material-icons text-white me-1">arrow_back_ios</span>戻る
      </button>
      <h1>成功</h1>
      <h2 class="mb-3">指定振替休日日</h2>
      <div class="row">
        <label class="text-muted m-0">
          代わりの振替休日日を入力してください。(平日のみ可)
        </label>
        <div class="col-sm-3">
          <input
            type="date"
            class="form-control"
            v-model="form.date"
            :class="{ 'is-invalid': !!errors.date }"
          />
          <div v-if="errors.date" class="invalid-feedback d-block">
            {{ errors.date[0] }}
          </div>
        </div>
        <h3>休日出勤日：{{ timecard.related_date }}</h3>
      </div>
      <button type="submit" class="btn btn-primary">&emsp;変更&emsp;</button>
    </form>
  </div>
</template>
<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";
export default {
  data: () => ({
    errors: {},
    timecard: {},
    today: "",
    form: {
      kind: "",
      date: "",
    },
  }),
  mounted() {
    client
      .get("/api/timecard/substitute_holiday/" + this.$route.params.id)
      .then((res) => {
        this.timecard = res.data;
        this.form.date = this.timecard.date;
        this.form.kind = this.timecard.kind ? String(this.timecard.kind) : "";
        this.$emit("loading", false);
      });
  },
  methods: {
    register() {
      this.$emit("loading", true);
      this.errors = {};

      client
        .put(
          `/api/timecard/substitute_holiday/${this.$route.params.id}`,
          this.form
        )
        .then((res) => {
          this.$emit("loading", false);
          this.$router.replace(
            "/timecard?registered=" +
              res.data.date +
              "&employee=" +
              res.data.summary.employee +
              "&month=" +
              res.data.summary.month
          );
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
            this.errorMessage = error.response.data.work_minutes;
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>
