<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">休日設定&emsp;登録・編集</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <form class="holiday-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <div class="mb-3">
          <label>対象年</label>
          <select
            class="form-select"
            v-model="form.year"
            :class="{ 'is-invalid': !!errors.year }"
          >
            <option v-for="year in yearSelection" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
          <div v-if="errors.year" class="invalid-feedback">
            {{ errors.year[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>日付</label>
          <input
            type="date"
            class="form-control"
            v-model="form.date"
            :class="{ 'is-invalid': !!errors.date }"
          />
          <div v-if="errors.date" class="invalid-feedback">
            {{ errors.date[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>名称</label>
          <input
            type="text"
            class="form-control"
            v-model="form.name"
            :class="{ 'is-invalid': !!errors.name }"
          />
          <div v-if="errors.name" class="invalid-feedback">
            {{ errors.name[0] }}
          </div>
        </div>
        <div class="mb-3">
          <div class="mb-1">対象者</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              name="target"
              id="target_office_worker"
              value="1"
              v-model="form.apply_office_worker"
              :class="{ 'is-invalid': !!errors.apply_office_worker }"
            />
            <label class="form-check-label" for="target_office_worker"
              >内勤者</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              name="target"
              id="target_store_worker"
              value="1"
              v-model="form.apply_store_worker"
              :class="{ 'is-invalid': !!errors.apply_store_worker }"
            />
            <label class="form-check-label" for="target_store_worker"
              >販売者</label
            >
          </div>
          <div
            v-if="errors.apply_office_worker"
            class="invalid-feedback d-block"
          >
            {{ errors.apply_office_worker[0] }}
          </div>
          <div
            v-if="errors.apply_store_worker"
            class="invalid-feedback d-block"
          >
            {{ errors.apply_store_worker[0] }}
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button type="submit" class="btn btn-primary">
            &emsp;登録&emsp;
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "HolidayFormView",
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    yearSelection: [],
    errors: {},
    form: {
      year: "",
      date: "",
      name: "",
      apply_office_worker: true,
      apply_store_worker: true,
    },
  }),
  watch: {
    id(value) {
      this.$emit("loading", true);
      this.errors = {};

      client
        .get(`/api/system/holiday/${value}`)
        .then((res) => {
          this.yearSelection = res.data.year_selections;
          this.form = { ...res.data.holiday };
          Object.keys(this.form).forEach((key) => {
            if (!key.startsWith("apply_")) {
              this.form[key] = this.form[key] || "";
            }
          });
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
  },
  methods: {
    register() {
      this.$emit("loading", true);
      let promise =
        this.id > 0
          ? client.put(`/api/system/holiday/${this.id}`, this.form)
          : client.post("/api/system/holiday", this.form);

      promise
        .then(() => {
          this.$emit("loading", false);
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
