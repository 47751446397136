<template>
  <div class="modal-content">
    <div class="modal-header py-2">
      <h6 class="modal-title" id="form_title">組織管理&emsp;登録・編集</h6>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <form class="department-form" @submit.prevent="register">
        <p v-if="errors.non_field_errors" class="invalid-feedback d-block">
          {{ errors.non_field_errors[0] }}
        </p>
        <div class="mb-3">
          <label>部署コード</label>
          <input
            type="text"
            class="form-control"
            v-model="form.code"
            :class="{ 'is-invalid': !!errors.code }"
            id="code"
          />
          <div v-if="errors.code" class="invalid-feedback">
            {{ errors.code[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>部署名</label>
          <input
            type="text"
            class="form-control"
            v-model="form.name"
            :class="{ 'is-invalid': !!errors.name }"
          />
          <div v-if="errors.name" class="invalid-feedback">
            {{ errors.name[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>親部署</label>
          <select
            class="form-select"
            v-model="form.parent_department"
            :class="{ 'is-invalid': !!errors.parent_department }"
          >
            <option value="">親部署なし</option>
            <option
              v-for="parent_department in parent_departments"
              :key="parent_department.code"
              :value="parent_department.code"
            >
              {{ parent_department.name }}
            </option>
          </select>
          <div v-if="errors.parent_department" class="invalid-feedback">
            {{ errors.parent_department[0] }}
          </div>
        </div>
        <div class="mb-3">
          <label>承認者</label>
          <select
            class="form-select"
            v-model="form.authorizer"
            :class="{ 'is-invalid': !!errors.authorizer }"
          >
            <option value="">承認者なし</option>
            <option
              v-for="authorizer in authorizers"
              :key="authorizer.identification"
              :value="authorizer.identification"
            >
              {{ authorizer.name }}
            </option>
          </select>
          <div v-if="errors.authorizer" class="invalid-feedback">
            {{ errors.authorizer[0] }}
          </div>
        </div>

        <div class="mb-3">
          <label>参照権限者</label>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-nowrap">
              <label
                ><input
                  type="checkbox"
                  v-model="showSelectedViewer"
                />&nbsp;対象者のみ表示</label
              >
            </div>
            <input
              type="text"
              class="form-control w-50"
              v-model="filterText"
              placeholder="社員番号/氏名"
            />
          </div>
          <div style="height: 200px; overflow: auto">
            <table class="table">
              <thead>
                <tr>
                  <th>選択</th>
                  <th>氏名</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="viewer in viewers"
                  :key="viewer.identification"
                >
                  <tr
                    v-if="
                      (!showSelectedViewer ||
                        form.viewers.indexOf(viewer.identification) !== -1) &&
                      (filterText === '' ||
                        viewer.identification.includes(filterText) ||
                        viewer.name.includes(filterText))
                    "
                    @click="toggleSelectViewer(viewer.identification)"
                  >
                    <td class="text-center">
                      <input
                        type="checkbox"
                        v-model="form.viewers"
                        :value="viewer.identification"
                      />
                    </td>
                    <td>{{ viewer.identification }}：{{ viewer.name }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="mb-3">
          <div class="mb-1">画面表示</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              name="target"
              id="valid"
              value="1"
              v-model="form.valid"
              :class="{ 'is-invalid': !!errors.valid }"
            />
            <label class="form-check-label" for="valid">表示する</label>
          </div>
          <div v-if="errors.valid" class="invalid-feedback d-block">
            {{ errors.valid[0] }}
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button type="submit" class="btn btn-primary">
            &emsp;登録&emsp;
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "@/plugins/axios";
import * as constants from "@/constants";

export default {
  name: "DepartmentFormView",
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    showSelectedViewer: false,
    authorizers: [],
    viewers: [],
    parent_departments: [],
    errors: {},
    form: {
      code: "",
      name: "",
      parent_department: "",
      authorizer: "",
      viewers: [],
      valid: true,
    },
    viewerSelectSettings: { language: "ja", allowClear: true },
    filterText: "",
  }),
  computed: {
    viewerSelection() {
      let selection = [];
      this.viewers.forEach((item) => {
        selection.push({
          id: item.identification,
          text: `${item.identification}:${item.name}`,
        });
      });
      return selection;
    },
  },
  watch: {
    id(value) {
      this.$emit("loading", true);
      this.errors = {};

      client
        .get(`/api/employee/department/${value}`)
        .then((res) => {
          this.authorizers = res.data.authorizers;
          this.viewers = res.data.viewers;
          this.parent_departments = res.data.parent_departments;
          this.form = { ...res.data.departments };
          Object.keys(this.form).forEach((key) => {
            this.form[key] = this.form[key] ?? "";
          });
          if (this.form.code === "") {
            let code = document.getElementById("code");
            code.removeAttribute("disabled");
          } else {
            let code = document.getElementById("code");
            code.setAttribute("disabled", "disabled");
          }
          this.$emit("loading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("loading", false);
        });
    },
  },
  methods: {
    toggleSelectViewer(identification) {
      let index = this.form.viewers.findIndex((v) => v === identification);

      console.log(index);

      if (index === -1) {
        this.form.viewers.push(identification);
      } else {
        this.form.viewers.splice(index, 1);
      }
    },
    register() {
      this.$emit("loading", true);
      let promise =
        this.id > 0
          ? client.put(`/api/employee/department/${this.id}`, this.form)
          : client.post("/api/employee/department", this.form);

      promise
        .then(() => {
          this.$emit("loading", false);
          this.$emit("registered");
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === constants.HTTP_RESPONSE_BAD_REQUEST) {
            this.errors = { ...error.response.data };
          }
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
table {
  th {
    position: sticky;
    top: 0;
    padding: 0.25rem;
  }

  tr:hover {
    background-color: #d1d2d4;
  }

  td {
    padding: 0.25rem;
  }
}
</style>
